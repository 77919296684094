import React, { useState, useCallback } from 'react'
import { AxiosResponse } from 'axios'
import { TextField, Grid } from '@material-ui/core'
import Helmet from 'react-helmet'
import debounce from 'lodash.debounce'

import {
  SessionLayout,
  SessionFormControl,
  CenteredRow,
  SubmitButton
} from './ui/sessions'
import SessionFooter from './SessionFooter'
import validateEmail from './validateEmail'
import SessionHeader from './SessionHeader'
import SsoLogin from './SsoLogin'
import SessionNotifications from './SessionNotifications'

import { useNotification } from '../../common/notifications/NotificationContext'
import { NOTIFICATION_TYPES } from '../../common/notifications/NotificationReducers'
import axiosSession from '../../utils/http/axios-sessions'
import Rollbar from '../../utils/rollbar'
import axios from '../../utils/http/axios-local'

const ONCHANGE_DEBOUNCE_TIME = 400

const defaults = {
  username_or_email: '',
  password: ''
}

const SessionLoginForm = () => {
  const [state, setState] = useState({ ...defaults })
  const [ssoUrl, setSsoUrl] = useState('url')
  const [buttonName, setButtonName] = useState('Login with Okta')

  const [showSso, setShowSso] = useState(false)
  const [isSsoOnly, setIsSsoOnly] = useState(false)
  const { dispatchNotification } = useNotification()

  const debouncedVerifySSO = useCallback(
    debounce((name, value) => {
      if (validateEmail(value)) {
        axios
          .get('/verify_sso.json', { params: { email: value } })
          .then(response => {
            setSsoUrl(response.data.url)
            setShowSso(response.data.sso_enabled)
            setIsSsoOnly(!response.data.password_login_allowed)

            if (response.data.name) {
              setButtonName('Login with ' + response.data.name.toUpperCase())
            }
          })
      }
    }, ONCHANGE_DEBOUNCE_TIME),
    [setSsoUrl, setShowSso, setButtonName]
  )

  const onChange = useCallback(
    ({ target: { name, value } }) => {
      debouncedVerifySSO(name, value)
      return setState(s => ({ ...s, [name]: value }))
    },
    [setState, debouncedVerifySSO]
  )

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    axiosSession
      .post('/sessions.json', { ...state })
      .then((res: AxiosResponse) => {
        const { url } = res.data
        if (url && url !== '') {
          window.location.href = url
        } else {
          Rollbar.error('Missing Redirect URL', res)
        }
      })
      .catch(res => {
        dispatchNotification({
          type: NOTIFICATION_TYPES.error,
          payload: {
            notification:
              res?.response?.status === 423
                ? 'Your Account Has Been Locked. Please See Administrator.'
                : 'Incorrect Password or Username'
          }
        })
        setState(s => ({ ...s, username_or_email: '', password: '' }))
      })
  }

  const isStandardLogicAvailable = !isSsoOnly || !showSso

  return (
    <SessionLayout>
      <Helmet>
        <title>Sift | Sign In</title>
      </Helmet>
      <SessionHeader title="Log in to Dispute Management" />
      <SessionNotifications />
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} zeroMinWidth>
            <SessionFormControl>
              <TextField
                variant="outlined"
                id="username"
                name="username_or_email"
                value={state.username_or_email}
                onChange={onChange}
                placeholder="Username"
                label="Username"
              />
            </SessionFormControl>
          </Grid>
          {isStandardLogicAvailable && (
            <>
              <Grid item xs={12} zeroMinWidth>
                <SessionFormControl>
                  <TextField
                    variant="outlined"
                    type="password"
                    id="password"
                    name="password"
                    value={state.password}
                    onChange={onChange}
                    placeholder="Password"
                    label="Password"
                  />
                </SessionFormControl>
              </Grid>
              <CenteredRow item xs={12} zeroMinWidth>
                <SubmitButton variant="contained" size="large">
                  sign in to your account
                </SubmitButton>
              </CenteredRow>
            </>
          )}
        </Grid>
      </form>
      {isStandardLogicAvailable && (
        <SessionFooter
          text="Can't log in?"
          linkText="Reset Password"
          to="/ui/reset"
        />
      )}
      {showSso ? (
        <SsoLogin linkText={buttonName} to={ssoUrl} isSsoOnly={isSsoOnly} />
      ) : (
        <br />
      )}
    </SessionLayout>
  )
}

export default SessionLoginForm
