import React, { useMemo, useState } from 'react'
import { Modal } from '@siftscience/focus-components/modal'
import { Title } from '@siftscience/focus-components/text'
import { Developer, Table } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  createButtonRenderer,
  SegmentedControl
} from '@siftscience/focus-components/segmented-control'
import { TextArea } from '@siftscience/focus-components/input'
import { Button } from '@siftscience/focus-components/button'
import JsonLogicBuilder from '../../disputebot-automation/json-logic-builder'
import JsonEditor from '../../disputebot-automation/json-editor'
import { useTemplateContext } from '../context/template-context'
import { TemplateDTO } from '../dto/template'

const MODAL_ACTIONS_TITLES = {
  removeRuleSet: 'Remove Rule Set',
  save: 'Save',
  cancel: 'Cancel'
}

const SEGMENTED_CONTROL_ITEMS = [
  <>
    <Table />
    <span>Builder</span>
  </>,
  <>
    <Developer />
    <span>Code</span>
  </>
]

const useStyles = makeStyles(() => ({
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 300
  },
  modalTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalContentWrapper: {
    paddingRight: '0 !important',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '1px',
      boxShadow: '0px 4px 2px 0px #24242429'
    },
    '&::before': {
      top: '-4px'
    },
    '&::after': {
      bottom: '4px'
    },
    position: 'relative'
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7F7',
    height: '440px'
  },
  rulesGeneration: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px',
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #E0E0E0',
    paddingRight: '24px',
    paddingTop: '16px'
  },
  description: {
    marginBottom: '12px',
    height: '332px'
  },
  codeBuilderWrapper: {
    padding: '16px 24px',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '16px',
    overflowY: 'auto'
  }
}))

interface CustomRuleSetModalProps {
  onCloseModal: () => void
  template: TemplateDTO
}

const CustomRuleSetModal = ({
  onCloseModal,
  template
}: CustomRuleSetModalProps) => {
  const { generateRules, saveEvidenceRules, jsonConfig } = useTemplateContext()
  const [activeMode, setActiveMode] = useState(0)
  const [description, setDescription] = useState(
    template?.evidenceRules?.[0]?.description || ''
  )
  const [rules, setRules] = useState(
    template?.evidenceRules?.[0]?.json_logic_ruleset
  )
  const [genAIRules, setGenAIRules] = useState(null)
  const classes = useStyles()

  const onAction = (action: { label }) => {
    if (action.label === MODAL_ACTIONS_TITLES.save) {
      const oldRule = template?.evidenceRules?.[0]
      const isEvidenceRuleNew = !oldRule?.id

      const data = {
        ...(template?.evidenceRules?.[0] || {}),
        det_template_id: template.templateId,
        description,
        json_logic_ruleset: rules
      }
      saveEvidenceRules(data, isEvidenceRuleNew).then(onCloseModal)
    } else {
      onCloseModal()
    }
  }

  const onRemoveRuleSet = () => {
    const oldRule = template?.evidenceRules?.[0]
    const isEvidenceRuleNew = !oldRule?.id

    const data = {
      ...(template?.evidenceRules?.[0] || {}),
      det_template_id: template.templateId,
      description: '',
      json_logic_ruleset: {}
    }
    saveEvidenceRules(data, isEvidenceRuleNew).then(onCloseModal)
  }

  const onDescriptionChange = event => {
    setDescription(event.target.value)
  }

  const onGenerateRules = () => {
    generateRules(description).then(data => {
      if (data) {
        setGenAIRules(data)
      }
    })
  }

  const onRulesChange = (rules: string) => {
    try {
      setRules(JSON.parse(rules))
    } catch (e) {
      console.error(e)
    }
  }

  const modalTitle = (
    <div className={classes.modalTitle}>
      <Title size="large">Custom Rule Set</Title>
      <SegmentedControl
        items={SEGMENTED_CONTROL_ITEMS}
        onSelect={(item, index) => setActiveMode(index)}
        selectedIndex={activeMode}
        size="medium"
        itemRenderer={createButtonRenderer()}
      />
    </div>
  )

  const stringRules = useMemo(() => {
    try {
      return JSON.stringify(rules)
    } catch (e) {
      console.error(e)
      return ''
    }
  }, [rules])

  return (
    <div className={classes.fixed}>
      <Modal
        contentContainerClassName={classes.modalContentWrapper}
        actions={[
          {
            label: MODAL_ACTIONS_TITLES.save,
            variant: 'primary'
          },
          {
            label: MODAL_ACTIONS_TITLES.cancel,
            lined: true,
            variant: 'secondary'
          }
        ]}
        footerContent={
          <Button lined variant="secondary" onClick={onRemoveRuleSet}>
            {MODAL_ACTIONS_TITLES.removeRuleSet}
          </Button>
        }
        closeable
        onAction={onAction}
        onClickOutside={onCloseModal}
        onCloseButtonClick={onCloseModal}
        size="large"
        title={modalTitle}
        variant="fixed"
        visible
      >
        <div className={classes.bodyWrapper}>
          <div className={classes.rulesGeneration}>
            <Title size="xsmall" color="secondary">
              Describe Rules
            </Title>
            <TextArea
              className={classes.description}
              value={description}
              onChange={onDescriptionChange}
            />
            <Button lined onClick={onGenerateRules}>
              Generate Rules
            </Button>
          </div>
          <div className={classes.codeBuilderWrapper}>
            {activeMode === 0 ? (
              <JsonLogicBuilder
                onRulesChange={onRulesChange}
                rules={stringRules || ''}
                genAIRules={genAIRules}
                onGenAIRulesApplied={() => setGenAIRules(null)}
                config={jsonConfig}
              />
            ) : (
              <JsonEditor
                onRulesChange={onRulesChange}
                rules={stringRules || ''}
                genAIRules={genAIRules}
                onGenAIRulesApplied={() => setGenAIRules(null)}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CustomRuleSetModal
