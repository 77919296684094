import React, { MouseEvent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton } from '@siftscience/focus-components/button'
import { Subtitle, Text, Title } from '@siftscience/focus-components/text'
import { Edit } from '@siftscience/focus-components/icons/Edit'
import { TextInput } from '@siftscience/focus-components/input'
import { Switch } from '@siftscience/focus-components/switch'
import { Info } from '@siftscience/focus-components/icons'
import { Select } from '@siftscience/focus-components/select'
import { TooltipTrigger } from '@siftscience/focus-components/tooltip'
import { Checkbox } from '@siftscience/focus-components/checkbox'
import capitalize from 'lodash/capitalize'
import { DataField, DataFieldType } from '../../dto/template'
import DatePicker from '../../../DET-edit-additions/date-picker'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '276px'
  },
  mainBody: {
    padding: '16px',
    borderBottom: '1px solid #E0E0E0'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '56px',
    padding: '12px 16px'
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E0E0E0',
    marginBottom: '8px'
  },
  pathWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  isRequired: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px'
  },
  globalSettingsTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '4px',
    marginBottom: '4px'
  },
  block: {
    marginBottom: '8px'
  },
  info: {
    width: 'auto',
    height: 'auto',
    borderRadius: '50%'
  },
  title: {
    marginBottom: '4px'
  },
  input: {
    width: '100%'
  },
  select: {
    width: '100%',

    '& button': {
      width: '100%'
    }
  },
  fieldWrapper: {
    margin: '4px 0'
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  }
}))

const DATA_FIELD_OPTIONS: DataFieldType[] = Object.values(DataFieldType)
const IMAGE_PATH_PREFIX = '$.evidences'

interface ActionProps {
  defaultDataType: DataFieldType
  onCancel: (event: MouseEvent) => void
  onSave: (dataField: DataField) => void
}

const Action = ({ defaultDataType, onCancel, onSave }: ActionProps) => {
  const [dataType, setDataType] = useState<DataFieldType>(defaultDataType)
  const [pathValue, setPathValue] = useState('')
  const [isPathEditable, setIsPathEditable] = useState(false)
  const [displayNameValue, setDisplayNameValue] = useState('')
  const [defaultValue, setDefaultValue] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (
      dataType === DataFieldType.IMAGE &&
      !pathValue?.startsWith(IMAGE_PATH_PREFIX)
    ) {
      setPathValue(IMAGE_PATH_PREFIX + (pathValue || ''))
    }
  }, [dataType, pathValue])

  const onPathChange = event => {
    const value = event.target.value
    if (
      dataType === DataFieldType.IMAGE &&
      !value?.startsWith(IMAGE_PATH_PREFIX)
    ) {
      // Image path should always start with '$.evidences'
      return
    }
    setPathValue(value)
  }

  const onDefaultValueChange = event => {
    const value = event.target.value
    setDefaultValue(value)
  }

  // TODO tooltipContent needs data from BE
  const tooltipContent = `This data field is used in 3 other templates.
  \n Changing the data type here will update it in:
   \n • Return Policy \n • Subscription Items \n • Billing Address`

  const onSaveAction = () => {
    // TODO add validation that path has '$.evidences' prefix for image
    // TODO to be enhanced with additional logic
    onSave({
      name: displayNameValue,
      data_type: dataType,
      required: isRequired,
      dataset_path: pathValue,
      default_value: defaultValue
    })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainBody}>
        <div className={classes.block}>
          <Title size="xsmall" color="secondary" className={classes.title}>
            Path
          </Title>
          <div className={classes.pathWrapper}>
            {isPathEditable ? (
              <TextInput
                value={pathValue}
                onChange={onPathChange}
                className={classes.input}
              />
            ) : (
              <>
                <Text size="small">{pathValue}</Text>
                <IconButton
                  onClick={() => setIsPathEditable(true)}
                  variant="secondary-ghost"
                >
                  <Edit />
                </IconButton>
              </>
            )}
          </div>
        </div>
        <div className={classes.separator} />
        <div className={classes.block}>
          <Title size="xsmall" color="secondary" className={classes.title}>
            Display Name
          </Title>
          <TextInput
            value={displayNameValue}
            onChange={event => setDisplayNameValue(event.target.value)}
            className={classes.input}
          />
          <div className={classes.isRequired}>
            <Switch
              checked={isRequired}
              onChange={() => setIsRequired(!isRequired)}
            />
            <Text size="small">Is Required</Text>
          </div>
        </div>
        <div className={classes.separator} />
        <div>
          <div className={classes.globalSettingsTitle}>
            <Subtitle color="secondary">GLOBAL DATA FIELD SETTINGS</Subtitle>
            <TooltipTrigger content={tooltipContent} placement="top">
              <IconButton
                className={classes.info}
                variant="secondary-ghost"
                size="medium"
              >
                <Info color="#B3B3B3" />
              </IconButton>
            </TooltipTrigger>
          </div>
          <div className={classes.fieldWrapper}>
            <Title size="xsmall" color="secondary" className={classes.title}>
              Data Type
            </Title>
            <Select
              className={classes.select}
              variant="secondary"
              items={DATA_FIELD_OPTIONS}
              getItemLabel={item => capitalize(item as string)}
              onChange={item => setDataType(item)}
              selectedItem={dataType}
              stretch
            />
          </div>
          {!!dataType &&
            dataType !== DataFieldType.IMAGE &&
            dataType !== DataFieldType.ARRAY && (
              <div className={classes.fieldWrapper}>
                <Title
                  size="xsmall"
                  color="secondary"
                  className={classes.title}
                >
                  Default Value
                </Title>
                {dataType === DataFieldType.TEXT && (
                  <TextInput
                    className={classes.select}
                    value={defaultValue}
                    onChange={onDefaultValueChange}
                    placeholder="No default value"
                  />
                )}
                {dataType === DataFieldType.BOOLEAN && (
                  <div className={classes.checkboxWrapper}>
                    <Checkbox onChange={onDefaultValueChange} />
                  </div>
                )}
                {dataType === DataFieldType.DATE && (
                  <DatePicker
                    value={defaultValue as string}
                    onChange={onDefaultValueChange}
                  />
                )}
                {dataType === DataFieldType.DATETIME && (
                  <DatePicker
                    value={defaultValue as string}
                    type="datetime-local"
                    onChange={onDefaultValueChange}
                  />
                )}
              </div>
            )}
        </div>
      </div>
      <div className={classes.actions}>
        <Button variant="secondary" lined onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSaveAction}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default Action
