import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { TextInput } from '@siftscience/focus-components/input'
import { Action, AutomationDTO } from './dto/automation'
import { useAutomationContext } from './context/automation-context'
import TemplateToolingHeaderDropdown from '../template-tooling/dropdown'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '6px'
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px'
  },
  action: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px'
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: '#E0E0E0'
  }
}))

interface ActionArgumentProps {
  index: number
  action: Action
  onArgumentChange: (index: number, property: string, value: any) => void
}

const ActionArgument = ({
  index,
  action,
  onArgumentChange
}: ActionArgumentProps) => {
  const { config } = useAutomationContext()
  const name = action?.automation_name
  const actionParams = config?.actions[name]
  const actionPropertiesParams = actionParams?.args?.properties || {}
  const actionProperties = Object.keys(actionPropertiesParams)
  if (!actionProperties.length) {
    return null
  }

  return actionProperties.map(property => {
    const propertyParams = actionPropertiesParams?.[property]
    const type = propertyParams?.type
    const enumList = propertyParams?.enum
    const maxLength = propertyParams?.maxLength
    const value = action?.arguments?.[property]

    const onChange = event => {
      const value = event?.target?.value
      onArgumentChange(index, property, value)
    }

    if (!enumList && (type === 'string' || type === 'integer')) {
      return (
        <TextInput
          key={`${action.id}-${action.automation_name}-${index}-${property}`}
          value={value as string}
          onChange={onChange}
          maxLength={maxLength}
          placeholder={property}
        />
      )
    }

    if (enumList && enumList.length) {
      const items = enumList.map(item => ({ id: item, name: item }))
      return (
        <TemplateToolingHeaderDropdown
          key={`${action.id}-${action.automation_name}-${index}-${property}`}
          title={property}
          items={items}
          onSelect={selectedValue =>
            onArgumentChange(index, property, selectedValue.id)
          }
          selectedItem={{ id: value as string }}
          placeholder="Select Action"
        />
      )
    }

    return null
  })
}

interface ActionsFormProps {
  automation: AutomationDTO
  onChangeActions: (actions: Action[]) => void
}

const ActionsForm = ({ automation, onChangeActions }: ActionsFormProps) => {
  const { config } = useAutomationContext()
  const classes = useStyles()

  const onAddAction = () => {
    const actions = [...(automation?.actionsAttributes || [])]
    actions.push({ automation_name: '' })
    onChangeActions(actions)
  }

  const onChangeAction = (index: number, property: string, value: any) => {
    if (!automation?.actionsAttributes?.length) {
      return
    }
    const actions = [...automation?.actionsAttributes]
    if (!actions[index]) {
      return
    }

    actions[index][property] = value
    onChangeActions(actions)
  }

  const onChangeActionArgument = (
    index: number,
    property: string,
    value: any
  ) => {
    if (!automation?.actionsAttributes?.length) {
      return
    }
    const actions = [...automation?.actionsAttributes]
    if (!actions[index]) {
      return
    }

    if (!actions[index].arguments) {
      actions[index].arguments = {}
    }

    actions[index].arguments[property] = value
    onChangeActions(actions)
  }

  const onDestroyAction = (index: number) => {
    if (!automation?.actionsAttributes?.length) {
      return
    }
    const actions = [...automation?.actionsAttributes]
    if (!actions[index]) {
      return
    }

    actions[index]['_destroy'] = !actions[index]['_destroy']
    onChangeActions(actions)
  }

  const onDeleteAction = (index: number) => {
    if (!automation?.actionsAttributes?.length) {
      return
    }
    const actions = [...automation?.actionsAttributes]
    if (!actions[index]) {
      return
    }

    actions.splice(index, 1)
    onChangeActions(actions)
  }

  if (!config?.actions) {
    return null
  }

  const actionItems = Object.keys(config?.actions).map(action => ({
    id: action,
    name: action
  }))
  const existingActions = automation?.actionsAttributes || []

  return (
    <div className={classes.wrapper}>
      <Title size="xsmall" color="secondary">
        What to do after Automation was triggered
      </Title>
      {existingActions.length
        ? existingActions.map((action, index) => (
            <div
              className={classes.actionWrapper}
              key={`${action.id}-${action.automation_name}-${index}`}
            >
              <div className={classes.action}>
                <TemplateToolingHeaderDropdown
                  title="Action name"
                  items={actionItems}
                  onSelect={selectedAction =>
                    onChangeAction(index, 'automation_name', selectedAction.id)
                  }
                  selectedItem={{ id: action?.automation_name }}
                  placeholder="Select Action"
                />
                <ActionArgument
                  index={index}
                  action={action}
                  onArgumentChange={onChangeActionArgument}
                />
                {!!action.id && (
                  <Button
                    onClick={() => onDestroyAction(index)}
                    variant="secondary"
                  >
                    {action._destroy ? 'Restore' : 'Destroy'}
                  </Button>
                )}
                {!action.id && (
                  <Button
                    onClick={() => onDeleteAction(index)}
                    variant="secondary"
                    lined
                  >
                    Delete
                  </Button>
                )}
              </div>
              {index + 1 !== existingActions.length && (
                <div className={classes.separator} />
              )}
            </div>
          ))
        : null}
      <Button variant="secondary" size="large" onClick={onAddAction}>
        Add Action
      </Button>
    </div>
  )
}

export default ActionsForm
