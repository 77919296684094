import React, { useState } from 'react'
import { Text, Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { Eye, Plus } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import { TemplateDTO } from '../dto/template'
import CustomRuleSetModal from './custom-rule-set-modal'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0',
    '& div': {
      lineHeight: 'normal'
    }
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

interface CustomRuleSetRowProps {
  readonly?: boolean
  template?: TemplateDTO
}

const CustomRuleSetRow = ({ readonly, template }: CustomRuleSetRowProps) => {
  const [modalState, setModalState] = useState({ visible: false })
  const classes = useStyles()
  const rules = template?.evidenceRules?.[0]?.json_logic_ruleset
  const areRulesEmpty = Object.keys(rules || {})?.length === 0

  const onOpenModal = () => {
    setModalState({ visible: true })
  }

  const onCloseModal = () => {
    setModalState({ visible: false })
  }

  const description = template?.evidenceRules?.[0]?.description

  return (
    <div className={classes.actionBlock}>
      <div>
        <Title size="xsmall">Custom Rule Set</Title>
        {description ? (
          <div>
            <Text size="xsmall" color="secondary">
              {description}
            </Text>
          </div>
        ) : null}
      </div>
      {readonly && !areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Eye />
          <Title size="xsmall">Show Rule Set</Title>
        </Button>
      )}
      {!readonly && areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Plus />
          <Title size="xsmall">Add Rule Set</Title>
        </Button>
      )}
      {!readonly && !areRulesEmpty && (
        <Button
          variant="secondary"
          className={classes.actionButton}
          lined
          onClick={onOpenModal}
        >
          <Plus />
          <Title size="xsmall">Edit Rule Set</Title>
        </Button>
      )}
      {modalState?.visible ? (
        <CustomRuleSetModal onCloseModal={onCloseModal} template={template} />
      ) : null}
    </div>
  )
}

export default CustomRuleSetRow
