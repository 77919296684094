import React from 'react'

interface PaginationProps {
  page: number
  size: number
  total: number
  setPage: (page: number) => void
  setSize: (size: number) => void
}

const Pagination = ({
  page,
  size,
  total,
  setSize,
  setPage
}: PaginationProps) => {
  const numberOfPages = Math.ceil(total / size)

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        justifyContent: 'center',
        padding: 16
      }}
    >
      <button onClick={() => setPage(page - 1)} disabled={page === 1}>
        {'<'}
      </button>
      <button
        onClick={() => setPage(page + 1)}
        disabled={page === numberOfPages}
      >
        {'>'}
      </button>
      <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <div>Page</div>
        <strong>
          {page} of {numberOfPages}
        </strong>
      </span>
      <select
        value={size}
        onChange={e => {
          setPage(0)
          setSize(Number(e.target.value))
        }}
      >
        {[10, 20, 30, 40, 50, 100].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Pagination
