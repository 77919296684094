import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextArea, TextInput } from '@siftscience/focus-components/input'
import { Text, Title } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { MultiSelect, Option } from '@siftscience/focus-components/select'
import { Switch } from '@siftscience/focus-components/switch'
import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import TemplateToolingHeaderDropdown from '../template-tooling/dropdown'
import { useAutomationContext } from './context/automation-context'
import { useMerchantContext } from './context/merchant-context'
import JsonLogicBuilder from './json-logic-builder'
import JsonEditor from './json-editor'
import ActionsForm from './actions-form'
import axios from '../../utils/http/axios-local'
import { AutomationDTO } from './dto/automation'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7F7',
    flex: 1,
    height: 'calc(100vh - 84px - 64px - 34px)' // 84 for automation header, 64 for global header, and 34 for footer
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '16px',
    width: '30%',
    padding: '16px 24px',
    borderRight: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    overflowY: 'auto'
  },
  codeBuilderWrapper: {
    padding: '16px 24px',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '16px',
    overflowY: 'auto'
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px'
  },
  rowField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px'
  }
}))

const AutomationView = () => {
  const {
    automationId,
    automation,
    setIsLoading,
    config,
    saveAutomation,
    activeMode
  } = useAutomationContext()
  const { displayMerchantList } = useMerchantContext()
  const [tempAutomation, setTempAutomation] = useState<AutomationDTO | null>(
    automation || { active: true }
  )
  const [genAIRules, setGenAIRules] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  useEffect(() => {
    if (automation) {
      setTempAutomation(automation)
    }
  }, [automation])

  const onTempAutomationChange = (value, field: keyof AutomationDTO) => {
    setTempAutomation(prevAutomation => ({
      ...(prevAutomation || {}),
      [field]: value
    }))
  }

  const onGenerateRules = () => {
    setIsLoading(true)
    axios
      .post<string>('/api/automations/resolve_json_logic', {
        text: tempAutomation.description
      })
      .then(response => {
        // TODO finish it, BE is not ready yet
        if (response.data) {
          setGenAIRules(response.data)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  const handleError = error => {
    const statusCode = error.response.status
    const responseData = error.response.data
    let errorText = ''

    if (statusCode === 422 && responseData) {
      const errorKeys = Object.keys(responseData)
      errorText = errorKeys.reduce((acc, key, currentIndex) => {
        if (currentIndex !== 0) {
          acc += '; '
        }
        const message = responseData[key]?.join(',')
        acc += `${key} - ${message}`
        return acc
      }, '')
      errorText = capitalize(errorText)
    }

    enqueueSnackbar(
      <div>
        <div>
          <Title>Failed to save automation</Title>
        </div>
        <div>
          <Text>{errorText}</Text>
        </div>
      </div>,
      {
        variant: 'error'
      }
    )
  }

  const onSave = () => {
    setIsLoading(true)
    if (automationId) {
      saveAutomation(tempAutomation)
        .then(() => setIsLoading(false))
        .catch(error => {
          handleError(error)
          setIsLoading(false)
        })
    } else {
      axios
        .post<AutomationDTO>('/api/automations', tempAutomation)
        .then(response => {
          setIsLoading(false)
          window.location.href = `/ui/disputebot/automation/${response.data?.id}`
        })
        .catch(error => {
          handleError(error)
          setIsLoading(false)
        })
    }
  }

  const eventItems = useMemo(() => {
    if (!config) {
      return []
    }
    const events = config.events
    return Object.keys(events).map(eventKey => ({
      label: events[eventKey]?.title,
      value: eventKey
    }))
  }, [config])

  const onEventsChange = selectedEvents => {
    const eventKeys = selectedEvents.map(event => event.value)

    onTempAutomationChange(eventKeys, 'onEvents')
  }

  const onRulesChange = (rules: string) => {
    onTempAutomationChange(rules, 'rules')
  }

  const onActiveChange = () => {
    onTempAutomationChange(!tempAutomation?.active, 'active')
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.form}>
        <div className={classes.field}>
          <Title size="xsmall" color="secondary">
            Name
          </Title>
          <TextInput
            value={tempAutomation?.name}
            onChange={event =>
              onTempAutomationChange(event?.target?.value, 'name')
            }
          ></TextInput>
        </div>
        <div className={classes.rowField}>
          <Title size="xsmall" color="secondary">
            Active
          </Title>
          <Switch
            key={`${tempAutomation?.active}`}
            checked={tempAutomation?.active}
            onChange={onActiveChange}
          />
        </div>
        <div className={classes.field}>
          <TemplateToolingHeaderDropdown
            title="Merchant"
            items={displayMerchantList || []}
            onSelect={merchant =>
              onTempAutomationChange(merchant.id, 'merchantId')
            }
            selectedItem={{ id: tempAutomation?.merchantId }}
            placeholder="Select Merchant"
          />
        </div>
        <div className={classes.field}>
          <Title size="xsmall" color="secondary">
            Describe Automation Rules
          </Title>
          <TextArea
            value={tempAutomation?.description}
            onChange={event =>
              onTempAutomationChange(event.target.value, 'description')
            }
          />
        </div>
        <div className={classes.field}>
          <Button lined size="large" onClick={onGenerateRules}>
            Generate Rules
          </Button>
        </div>
        <div className={classes.field}>
          <Title size="xsmall" color="secondary">
            When to trigger the Automation
          </Title>
          <MultiSelect
            items={eventItems}
            selectedItemsRenderer={items =>
              items?.length === 1
                ? (items[0] as Option).label
                : `${items?.length}x items selected`
            }
            placeholder="Please, select events"
            variant="secondary"
            onChange={onEventsChange}
            selectedItems={eventItems.filter(item =>
              tempAutomation?.onEvents?.includes(item?.value)
            )}
          />
        </div>
        <div className={classes.field}>
          <Button size="large" onClick={onSave}>
            {automationId ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>
      <div className={classes.codeBuilderWrapper}>
        {activeMode === 0 ? (
          <JsonLogicBuilder
            onRulesChange={onRulesChange}
            rules={tempAutomation?.rules || ''}
            genAIRules={genAIRules}
            onGenAIRulesApplied={() => setGenAIRules(null)}
            config={config}
          />
        ) : (
          <JsonEditor
            onRulesChange={onRulesChange}
            rules={tempAutomation?.rules || ''}
            genAIRules={genAIRules}
            onGenAIRulesApplied={() => setGenAIRules(null)}
          />
        )}
        <div className={classes.field}>
          <ActionsForm
            automation={tempAutomation}
            onChangeActions={actions =>
              onTempAutomationChange(actions, 'actionsAttributes')
            }
          />
        </div>
      </div>
    </div>
  )
}

export default AutomationView
