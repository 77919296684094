import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'
import { TemplateDTO } from './dto/template'

export const useTemplate = (templateId: number) => {
  const {
    data: templateData,
    isLoading: isTemplateLoading,
    refetch: refetchTemplate
  } = useQuery(
    `template-${templateId}`,
    () => axios.get<TemplateDTO>(`/det/tooling/templates/${templateId}`),
    {
      refetchOnWindowFocus: false,
      enabled: !!templateId
    }
  )

  return {
    templateData: (templateData?.data ?? {}) as TemplateDTO,
    isTemplateLoading,
    refetchTemplate
  }
}
