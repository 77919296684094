import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TemplateDTO } from '../dto/template'
import { Text, Title } from '@siftscience/focus-components/text'
import { useTemplateContext } from '../context/template-context'
import { ChevronRight } from '@siftscience/focus-components/icons'

const getParentList = (parentList: string) => {
  if (!parentList) {
    return []
  }

  return parentList
    ?.split('-')
    ?.map(id => +id)
    ?.reverse()
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  parentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    marginRight: '8px'
  },
  text: {
    marginRight: '4px'
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  }
}))

interface TemplateHeaderProps {
  template: TemplateDTO
}

const TemplateHeader = ({
  template
}: TemplateHeaderProps): React.ReactElement => {
  const { templatesHash, parentList } = useTemplateContext()
  const classes = useStyles()
  const parentIds = getParentList(parentList)

  return (
    <div className={classes.wrapper}>
      <Title className={classes.title} size="large">
        {template?.displayName}
      </Title>
      {parentIds?.map(id => (
        <div key={id} className={classes.parentWrapper}>
          <ChevronRight className={classes.icon} color="#B3B3B3" />
          <Text className={classes.text} size="xsmall">
            {templatesHash.get(id)?.displayName}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default TemplateHeader
