import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMerchants } from '../hooks/useMerchant'
import { MerchantDTO } from '../dto/merchant'

export const NONE_MERCHANT = { id: -1000, name: 'None', processors: [] }

const addNoneToList = merchants => {
  return [NONE_MERCHANT, ...merchants]
}

interface IMerchantContext {
  merchantList?: MerchantDTO[]
  displayMerchantList?: MerchantDTO[]
  selectedMerchant?: number
  setSelectedMerchant?: (merchantId: number) => void
  isMerchantsLoading?: boolean
}

const MerchantContext = createContext<IMerchantContext>({})

export const MerchantProvider = ({ children }) => {
  const { merchantsData, isMerchantsLoading } = useMerchants()
  const [selectedMerchant, setSelectedMerchant] = useState<number>()
  const [displayMerchantList, setDisplayMerchantList] = useState<MerchantDTO[]>(
    []
  )

  useEffect(() => {
    if (!merchantsData) {
      return
    }

    if (merchantsData.length === 1) {
      setSelectedMerchant(merchantsData[0]?.id)
      setDisplayMerchantList(merchantsData)
      return
    }

    const listWithNone = addNoneToList(merchantsData)
    setDisplayMerchantList(listWithNone)
    setSelectedMerchant(listWithNone[0]?.id)
  }, [merchantsData])

  const contextValue = useMemo(
    () => ({
      merchantList: merchantsData,
      selectedMerchant,
      isMerchantsLoading,
      setSelectedMerchant,
      displayMerchantList
    }),
    [
      merchantsData,
      selectedMerchant,
      isMerchantsLoading,
      setSelectedMerchant,
      displayMerchantList
    ]
  )

  return (
    <MerchantContext.Provider value={contextValue}>
      {children}
    </MerchantContext.Provider>
  )
}

export const useMerchantContext = () => {
  return useContext(MerchantContext)
}
