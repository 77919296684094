import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { AutomationDTO } from '../dto/automation'

export const useConfig = () => {
  const { data: { data: config } = {}, isLoading: isConfigLoading } = useQuery(
    'automation-config',
    () => axios.get('/api/automations/config'),
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    config,
    isConfigLoading
  }
}

const EMPTY_AUTOMATION = { active: true }

export const useAutomation = (id: string) => {
  if (!id) {
    return {
      automation: EMPTY_AUTOMATION,
      isAutomationLoading: false
    }
  }
  const { data: { data: automation } = {}, isLoading: isAutomationLoading } =
    useQuery(`automation-${id}`, () => axios.get(`/api/automations/${id}`), {
      refetchOnWindowFocus: false
    })

  return {
    automation: automation || EMPTY_AUTOMATION,
    isAutomationLoading
  }
}

// Not hook, but still this place is good enough for this method
export const saveChangesToAutomation = async (automation: AutomationDTO) => {
  if (!automation?.id) {
    return
  }
  return axios.put(`/api/automations/${automation.id}`, automation)
}

export const testAutomation = async (
  automationId: string | number,
  disputeId: string
) => {
  if (!automationId) {
    return
  }

  return axios.get(
    `/api/automations/${automationId}/test_dispute?dispute_id=${disputeId}`
  )
}
