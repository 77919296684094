import React, { useState, useEffect } from 'react'
import 'ace-builds/src-noconflict/ace'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'
import { makeStyles } from '@material-ui/core/styles'
import AutomationTest from './automation-test'

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '8px'
  }
}))

interface JsonEditorProps {
  rules: string
  onRulesChange: (rules: string) => void
  genAIRules: string
  onGenAIRulesApplied: () => void
}

const JsonEditor = ({
  rules,
  onRulesChange,
  genAIRules,
  onGenAIRulesApplied
}: JsonEditorProps) => {
  const [stringTree, setStringTree] = useState('')
  const [treeWasChanged, setTreeWasChanged] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (rules && !treeWasChanged) {
      // Need to run this only on initial render
      try {
        const jsonTree = JSON.parse(rules)
        setStringTree(JSON.stringify(jsonTree, null, 2))
      } catch (e) {
        console.error(e)
      }
    }
  }, [rules, treeWasChanged])

  const onEditorChange = (value: string) => {
    setTreeWasChanged(true)
    setStringTree(value)
    onRulesChange(value)
  }

  useEffect(() => {
    if (genAIRules) {
      const jsonTree = JSON.parse(genAIRules)
      onEditorChange(JSON.stringify(jsonTree, null, 2))
      onGenAIRulesApplied()
    }
  }, [genAIRules, onGenAIRulesApplied])

  return (
    <>
      <div className={classes.buttonWrapper}>
        <AutomationTest />
      </div>
      <AceEditor
        placeholder="You can type your JSON here"
        mode="json"
        theme="tomorrow"
        name="jsonLogic"
        onChange={onEditorChange}
        fontSize={14}
        width={'100%'}
        value={stringTree}
        showPrintMargin
        showGutter
        highlightActiveLine
        enableBasicAutocompletion
        enableLiveAutocompletion
        setOptions={{ useWorker: false }}
      />
    </>
  )
}

export default JsonEditor
