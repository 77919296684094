import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import CurrentUserStore from '../../contexts/CurrentUserStore'

const SuperAdminRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useCurrentUser()

  if (currentUser.id === CurrentUserStore.id) {
    return null
  }

  const roles = currentUser.roles
  const isAllowed = roles.some(role => role === 'Super Administrator')
  return (
    <Route
      {...rest}
      render={props =>
        isAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/ui/disputes', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default SuperAdminRoute
