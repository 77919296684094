import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMerchants } from '../hooks/useMerchant'
import { MerchantDTO } from '../dto/merchant'

const MERCHANT_PARAM_NAME = 'merchant'

export const NONE_MERCHANT = { id: -1000, name: 'None', processors: [] }

const addNoneToList = merchants => {
  return [NONE_MERCHANT, ...merchants]
}

interface IMerchantContext {
  merchantList?: MerchantDTO[]
  displayMerchantList?: MerchantDTO[]
  selectedMerchant?: number
  setSelectedMerchant?: (merchantId: number) => void
  isMerchantsLoading?: boolean
}

const MerchantContext = createContext<IMerchantContext>({})

export const MerchantProvider = ({ children }) => {
  const history = useHistory()
  const { merchantsData, isMerchantsLoading } = useMerchants()
  const [selectedMerchant, setSelectedMerchant] = useState<number>()
  const [displayMerchantList, setDisplayMerchantList] = useState<MerchantDTO[]>(
    []
  )
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const merchantParamId = searchParams.get(MERCHANT_PARAM_NAME)

  const selectMerchant = (merchantId: number) => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('merchant', `${merchantId}`)
    history.push({
      ...history.location,
      search: searchParams.toString()
    })
    setSelectedMerchant(merchantId)
  }

  useEffect(() => {
    if (merchantParamId) {
      selectMerchant(+merchantParamId)
    }
  }, [merchantParamId])

  useEffect(() => {
    if (!merchantsData) {
      return
    }

    if (merchantsData.length === 1) {
      selectMerchant(merchantsData[0]?.id)
      setDisplayMerchantList(merchantsData)
      return
    }

    const listWithNone = addNoneToList(merchantsData)
    setDisplayMerchantList(listWithNone)

    if (merchantParamId) {
      selectMerchant(+merchantParamId)
    } else {
      selectMerchant(listWithNone[0]?.id)
    }
  }, [merchantsData, merchantParamId])

  const contextValue = useMemo(
    () => ({
      merchantList: merchantsData,
      selectedMerchant,
      isMerchantsLoading,
      setSelectedMerchant: selectMerchant,
      displayMerchantList
    }),
    [
      merchantsData,
      selectedMerchant,
      isMerchantsLoading,
      selectMerchant,
      displayMerchantList
    ]
  )

  return (
    <MerchantContext.Provider value={contextValue}>
      {children}
    </MerchantContext.Provider>
  )
}

export const useMerchantContext = () => {
  return useContext(MerchantContext)
}
