import React, { useEffect, useState } from 'react'
import { Title } from '@siftscience/focus-components/text'
import { Select } from '@siftscience/focus-components/select'
import { makeStyles } from '@material-ui/core/styles'
import { renderSearchFieldHeader } from '@siftscience/focus-components/dropdown'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '4px',
    '& .focus-select-button': {
      width: '220px'
    }
  }
}))

interface DropdownItem {
  name?: string
  id: number | string
}

interface TemplateToolingHeaderDropdownProps {
  title: string
  placeholder?: string
  items: DropdownItem[]
  selectedItem?: DropdownItem
  onSelect: (item: DropdownItem) => void
}

const TemplateToolingHeaderDropdown = ({
  title,
  placeholder,
  items,
  selectedItem,
  onSelect
}: TemplateToolingHeaderDropdownProps): React.ReactElement => {
  const [displayItems, setDisplayItems] = useState(items)
  const [searchFilter, setSearchFilter] = useState('')
  const classes = useStyles()

  const onFilter = event => {
    const value = event?.target?.value
    setSearchFilter(value)
  }

  const onIsOpenChange = () => {
    setSearchFilter('')
  }

  useEffect(() => {
    if (!items) {
      return
    }
    const filteredItems = items.filter(
      item =>
        `${item.id}`?.toLowerCase()?.includes(searchFilter?.toLowerCase()) ||
        item?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
    )
    setDisplayItems(filteredItems)
  }, [items, searchFilter])

  return (
    <div className={classes.wrapper}>
      <Title size="xsmall" color="secondary">
        {title}
      </Title>
      <Select
        items={displayItems}
        getItemLabel={(item: DropdownItem) => item.name}
        selectedItem={items?.find(item => item.id === selectedItem?.id)}
        onChange={onSelect}
        placeholder={placeholder}
        variant="secondary"
        strategy="fixed"
        headerRenderer={renderSearchFieldHeader({ onChange: onFilter })}
        onIsOpenChange={onIsOpenChange}
        stretch
      />
    </div>
  )
}

export default TemplateToolingHeaderDropdown
