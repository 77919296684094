import ReportingCategory from '../../common/list-view/data-table/custom-columns/reporting-category'
import CardType from '../../common/list-view/data-table/custom-columns/card-type'
import Stage from '../../common/list-view/data-table/custom-columns/stage'
import LifecycleStatus from '../../common/list-view/data-table/custom-columns/lifecycle-status'
import Disposition from '../../common/list-view/data-table/custom-columns/disposition'
import CaseNumber from '../../common/list-view/data-table/custom-columns/case-number'
import ReferenceNumber from '../../common/list-view/data-table/custom-columns/referenece-number'
import Currency from '../../common/list-view/data-table/custom-columns/currency'
import CardPrefix from '../../common/list-view/data-table/custom-columns/card-prefix'
import CardSuffix from '../../common/list-view/data-table/custom-columns/card-suffix'
import Date from '../../common/list-view/data-table/custom-columns/date'
import InvoiceId from '../../common/list-view/data-table/custom-columns/invoice-id'
import TransactionId from '../../common/list-view/data-table/custom-columns/transaction-id'
import Notes from '../../common/list-view/data-table/custom-columns/notes'
import RemoteDeliveryStatus from '../../common/list-view/data-table/custom-columns/remote-delivery-status'

import { rangeChoices } from '../../common/list-view/search/add-dynamic-date-ranges'

import DataAdapter from '../../common/list-view/data-handler/interfaces/data-adapter-interface'

const dataAdapter: DataAdapter = {
  name: 'disputes',
  pagination: {
    page: 1,
    perPage: 50
  },
  columns: {
    reportingCategory: {
      type: 'string[]',
      title: 'Category',
      name: 'category',
      path: 'category',
      order: 1,
      format: ReportingCategory
    },
    cardType: {
      type: 'string[]',
      title: 'Card Type',
      name: 'cardType',
      path: 'cardType',
      order: 2,
      format: CardType
    },
    clientType: {
      type: 'string[]',
      title: 'Client Type',
      name: 'clientType',
      path: 'clientType'
    },
    reasonCode: {
      type: 'string[]',
      title: 'Reason Code',
      name: 'reasonCode',
      path: 'reasonCode',
      order: 3
    },
    stage: {
      type: 'string[]',
      title: 'Stage',
      name: 'stage',
      path: 'stage',
      order: 4,
      format: Stage
    },
    lifecycleStatus: {
      type: 'string[]',
      title: 'Status',
      name: 'lifecycleStatus',
      path: 'lifecycleStatus',
      default: ['new'],
      order: 5,
      format: LifecycleStatus
    },
    remoteDeliveryStatus: {
      type: 'string[]',
      title: 'External Status',
      name: 'remoteDeliveryStatus',
      path: 'remoteDeliveryStatus',
      order: 6,
      format: RemoteDeliveryStatus
    },
    disposition: {
      type: 'string[]',
      title: 'Outcome',
      name: 'disposition',
      path: 'disposition',
      order: 7,
      format: Disposition
    },
    portal: {
      type: 'number[]',
      title: 'Processor',
      name: 'portal',
      path: 'portal',
      order: 8
    },
    mid: {
      type: 'number[]',
      title: 'Mid',
      name: 'mid',
      path: 'mid',
      order: 9
    },
    merchant: {
      type: 'number[]',
      title: 'Merchant',
      name: 'merchant',
      path: 'merchant',
      order: 10
    },
    caseNumber: {
      type: 'string',
      title: 'Case #',
      name: 'caseNumber',
      path: 'caseNumber',
      order: 11,
      format: CaseNumber
    },
    referenceNumber: {
      type: 'string',
      title: 'Ref #',
      name: 'referenceNumber',
      path: 'referenceNumber',
      order: 12,
      format: ReferenceNumber
    },
    disputeAmount: {
      type: 'string',
      title: 'Dispute Amt',
      name: 'disputeAmount',
      path: 'disputeAmount',
      order: 13,
      sortable: true,
      format: Currency
    },
    cardPrefix: {
      type: 'string',
      title: 'Card Prefix',
      name: 'cardPrefix',
      path: 'cardPrefix',
      order: 14,
      sortable: true,
      format: CardPrefix
    },
    cardSuffix: {
      type: 'string',
      title: 'Card Suffix',
      name: 'cardSuffix',
      path: 'cardSuffix',
      order: 15,
      sortable: true,
      format: CardSuffix
    },
    requestDate: {
      type: 'date',
      title: 'Req Date',
      name: 'requestDate',
      path: 'requestDate',
      order: 16,
      sortable: true,
      sortDefault: 'desc',
      defaultRange: rangeChoices.lastFortyFiveDays,
      rangeStartName: 'request_date_after',
      rangeEndName: 'request_date_before',
      format: Date
    },
    expirationDate: {
      type: 'date',
      title: 'Exp Date',
      name: 'expirationDate',
      path: 'expirationDate',
      order: 17,
      sortable: true,
      rangeStartName: 'expiration_date_after',
      rangeEndName: 'expiration_date_before',
      format: Date
    },
    transactionTs: {
      type: 'date',
      title: 'Txn Date',
      name: 'transactionTs',
      path: 'transactionTs',
      order: 18,
      sortable: true,
      rangeStartName: 'transaction_ts_after',
      rangeEndName: 'transaction_ts_before',
      format: Date
    },
    invoiceId: {
      type: 'string',
      title: 'Invoice ID',
      name: 'invoiceId',
      path: 'invoiceId',
      order: 19,
      format: InvoiceId
    },
    transactionId: {
      type: 'string',
      title: 'Txn ID',
      name: 'transactionId',
      path: 'transactionId',
      order: 20,
      format: TransactionId
    },
    notes: {
      type: 'string',
      title: 'Notes',
      name: 'note',
      path: 'notes',
      order: 21,
      format: Notes
    },
    tags: {
      type: 'number[]',
      title: 'Tags',
      name: 'tags',
      path: 'tags',
      order: 22
    },
    assignedTo: {
      type: 'number[]',
      title: 'Assigned To',
      name: 'assignedTo',
      path: 'assignedTo',
      order: 23
    },
    ticketNumber: {
      type: 'string',
      title: 'Ticket Number',
      name: 'ticketNumber',
      path: 'ticketNumber',
      order: 24
    }
  }
}

export default dataAdapter
