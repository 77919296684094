import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { PaginationState, Table as ReactTable } from '@tanstack/react-table'
import { Table, TColumn } from '@siftscience/focus-components/table'
import { Modal } from '@siftscience/focus-components/modal'
import { Button } from '@siftscience/focus-components/button'
import DisputeBotListHeader from './header'
import Loader from './loader'
import { deleteAutomation, useAutomationList } from './hooks/useAutomation'
import Pagination from './pagination'
import { DisputebotListProvider } from './context'
import { useMerchantContext } from './context/merchant-context'
import { AutomationDTO } from '../disputebot-automation/dto/automation'
import capitalize from '../../utils/capitalize'

const MODAL_ACTIONS_TITLES = {
  delete: 'Delete',
  cancel: 'Cancel'
}

const useStyles = makeStyles(() => ({
  mainArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '16px 24px'
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px'
  },
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 300
  },
  idColumn: {
    maxWidth: 80
  },
  wrapColumn: {
    maxWidth: 200,
    textWrap: 'wrap'
  }
}))

const DisputeBotList = (): React.ReactElement => {
  const [modalState, setModalState] = useState({ visible: false, id: null })
  const [isDeleting, setIsDeleting] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const { selectedMerchant, merchantList } = useMerchantContext()
  const {
    automationList: {
      data: automationListData,
      meta: { total = null } = {}
    } = {},
    isAutomationListLoading
  } = useAutomationList(selectedMerchant, size, page)
  const [displayAutomations, setDisplayAutomations] = useState(
    automationListData || []
  )
  const classes = useStyles()
  const tableRef = useRef<ReactTable<AutomationDTO>>(null)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  })
  const history = useHistory()
  const onCloseDeleteModal = () => {
    setModalState({ visible: false, id: null })
  }

  const onDelete = (id: number) => {
    setModalState({ visible: true, id })
  }

  const onDeleteAutomation = async (id: number) => {
    try {
      setIsDeleting(true)
      await deleteAutomation(id)
      const updatedAutomationsList = displayAutomations.filter(
        automation => automation.id !== id
      )
      setDisplayAutomations(updatedAutomationsList)
      setIsDeleting(false)
    } catch (e) {
      console.error(e)
      setIsDeleting(false)
    }
  }

  const onAction = (action: { label }) => {
    if (action.label === MODAL_ACTIONS_TITLES.delete) {
      onDeleteAutomation(modalState?.id)
    }
    onCloseDeleteModal()
  }

  useEffect(() => {
    if (automationListData) {
      setDisplayAutomations(automationListData)
    }
  }, [automationListData])

  return (
    <>
      <Helmet>
        <title>Sift | {capitalize('Disputebot List')}</title>
      </Helmet>
      <div>
        <DisputeBotListHeader />
        <div className={classes.mainArea}>
          <Table
            data={displayAutomations}
            state={{
              pagination
            }}
            onPaginationChange={setPagination}
            getRowId={data => `${(data as AutomationDTO).id}`}
            tableInstanceRef={tableRef}
          >
            <TColumn accessor="id" header="ID" className={classes.idColumn} />
            <TColumn
              accessor="name"
              header="Name"
              className={classes.wrapColumn}
            />
            <TColumn accessor="active" header="Active" />
            <TColumn<AutomationDTO>
              accessor="merchantId"
              header="Merchant"
              cell={({ getValue }) => (
                <>
                  {
                    merchantList?.find(merchant => merchant.id === getValue())
                      ?.name
                  }
                </>
              )}
            />
            <TColumn
              accessor="description"
              header="Description"
              className={classes.wrapColumn}
            />
            <TColumn<AutomationDTO>
              accessor="onEvents"
              header="Trigger Events"
              className={classes.wrapColumn}
              cell={({ getValue }) => <>{getValue()?.join(', ')}</>}
            />
            <TColumn<AutomationDTO>
              accessor="id"
              header="Actions"
              cell={({ getValue }) => (
                <div className={classes.actionsWrapper}>
                  <Button
                    onClick={() =>
                      history.push(`/ui/disputebot/automation/${getValue()}`)
                    }
                  >
                    Edit
                  </Button>
                  <Button variant="danger" onClick={() => onDelete(getValue())}>
                    Delete
                  </Button>
                </div>
              )}
            />
          </Table>
          {total ? (
            <Pagination
              page={page}
              size={size}
              total={total}
              setPage={setPage}
              setSize={setSize}
            />
          ) : null}
        </div>
      </div>
      {isAutomationListLoading || isDeleting ? <Loader /> : null}

      {modalState?.visible ? (
        <div className={classes.fixed}>
          <Modal
            actions={[
              {
                label: MODAL_ACTIONS_TITLES.delete,
                variant: 'danger'
              },
              {
                label: MODAL_ACTIONS_TITLES.cancel,
                lined: true,
                variant: 'secondary'
              }
            ]}
            closeable
            onAction={onAction}
            onClickOutside={onCloseDeleteModal}
            onCloseButtonClick={onCloseDeleteModal}
            size="small"
            title={`Are you sure you want to delete automation ${modalState?.id}`}
            variant="fixed"
            visible
          />
        </div>
      ) : null}
    </>
  )
}

const DisputeBotListWithProviders = () => (
  <DisputebotListProvider>
    <DisputeBotList />
  </DisputebotListProvider>
)

export default DisputeBotListWithProviders
