import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTemplates } from '../hooks/useTemplate'
import { useMerchantContext } from './merchant-context'
import { useProcessorContext } from './processor-context'
import {
  EvidenceRule,
  JSONConfig,
  TemplateDTO,
  TemplateType
} from '../dto/template'
import {
  createNewTemplate,
  deleteTemplate,
  generateJsonLogic,
  postEvidenceRules,
  putTemplate
} from '../utils/template-utils'
import { useTemplate } from '../useTemplateTooling'
import { useJsonConfig } from '../hooks/useJsonConfig'

interface ITemplateContext {
  templateList?: TemplateDTO[]
  rootTemplates?: TemplateDTO[]
  activeTemplateData?: TemplateDTO
  templatesHash?: Map<number, TemplateDTO>
  activeTemplate?: number
  setActiveTemplate?: (id: number) => void
  isTemplatesLoading?: boolean
  searchValue: string
  setSearchValue: (value: string) => void
  selectTemplate: (id: number, parentList: string) => void
  parentList: string // list of template ids divided with '-'
  updateTemplate: (templateData: Partial<TemplateDTO>) => void
  createTemplate: (templateData: Partial<TemplateDTO>) => void
  updatedEvidenceRules: EvidenceRule
  setUpdatedEvidenceRules: (data: EvidenceRule) => void
  saveTemplateChanges: () => Promise<void>
  removeTemplate: (id: number) => Promise<void>
  isLoading: string
  generateRules: (description: string) => Promise<Record<string, unknown>>
  saveEvidenceRules: (rules: EvidenceRule, isNew: boolean) => Promise<void>
  jsonConfig: JSONConfig
}

const TemplateContext = createContext<ITemplateContext>({})

export const TemplateProvider = ({ children }) => {
  const { selectedMerchant } = useMerchantContext()
  const { selectedProcessor } = useProcessorContext()
  const { templatesData, isTemplatesLoading, refetchTemplates } = useTemplates(
    selectedMerchant?.id,
    selectedProcessor?.id
  )
  const { jsonConfig, isJsonConfigLoading } = useJsonConfig(
    selectedMerchant?.id
  )
  const [rootTemplates, setRootTemplates] = useState([])
  const [activeTemplate, setActiveTemplate] = useState<number | null>(null)
  const {
    templateData: activeTemplateData,
    isTemplateLoading,
    refetchTemplate
  } = useTemplate(activeTemplate)
  const [parentList, setParentList] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [templatesHash, setTemplatesHash] = useState<Map<number, TemplateDTO>>(
    new Map()
  )
  const [updatedEvidenceRules, setUpdatedEvidenceRules] =
    useState<EvidenceRule | null>(null)
  const [isGeneralLoading, setIsGeneralLoading] = useState(false)

  useEffect(() => {
    if (templatesData) {
      const filteredTemplates = templatesData.filter(template => {
        return (
          !template.parentTemplateIds?.length ||
          template.templateType === TemplateType.configurationPanel
        )
      })
      setRootTemplates(filteredTemplates)
    } else {
      setRootTemplates([])
    }
  }, [templatesData])

  useEffect(() => {
    if (templatesData) {
      const hash = new Map()
      templatesData.forEach(template => {
        hash.set(template.templateId, template)
      })
      setTemplatesHash(hash)
    } else {
      setTemplatesHash(new Map())
    }
  }, [templatesData])

  useEffect(() => {
    setActiveTemplate(null)
  }, [templatesData])

  const selectTemplate = (id: number, parentList: string) => {
    setActiveTemplate(id)
    setParentList(parentList)
  }

  const updateTemplate = async (templateData: Partial<TemplateDTO>) => {
    const { data } = await putTemplate(
      activeTemplate,
      selectedMerchant?.id,
      templateData
    )
    // TODO finish during ticket for saving changes
  }

  const createTemplate = async (templateData: Partial<TemplateDTO>) => {
    const { data } = await createNewTemplate(
      activeTemplate,
      selectedMerchant?.id,
      templateData
    )
    // TODO finish it, url doesn't exist yet
  }

  const removeTemplate = async (id: number) => {
    try {
      await deleteTemplate(id)
      setActiveTemplate(null)
      refetchTemplates()
    } catch (e) {
      console.error(e)
    }
    // TODO finish it, url doesn't exist yet
  }

  const saveTemplateChanges = async () => {
    // TODO call to BE
  }

  const saveEvidenceRules = async (rules: EvidenceRule, isNew: boolean) => {
    if (rules) {
      try {
        setIsGeneralLoading(true)
        await postEvidenceRules(rules, isNew)
        setIsGeneralLoading(false)
        refetchTemplate()
      } catch (e) {
        console.error(e)
        setIsGeneralLoading(false)
      }
    }
  }

  const generateRules = async (description: string) => {
    try {
      setIsGeneralLoading(true)
      const rules = await generateJsonLogic(description)
      setIsGeneralLoading(false)
      return rules?.data
    } catch (e) {
      console.error(e)
      setIsGeneralLoading(false)
      return null
    }
  }

  const isLoading =
    isTemplatesLoading ||
    isTemplateLoading ||
    isGeneralLoading ||
    isJsonConfigLoading

  const contextValue = useMemo(
    () => ({
      templateList: templatesData,
      isTemplatesLoading,
      rootTemplates,
      templatesHash,
      activeTemplate,
      selectTemplate,
      searchValue,
      setSearchValue,
      parentList,
      updateTemplate,
      activeTemplateData,
      updatedEvidenceRules,
      setUpdatedEvidenceRules,
      saveTemplateChanges,
      createTemplate,
      removeTemplate,
      isLoading,
      generateRules,
      saveEvidenceRules,
      jsonConfig
    }),
    [
      templatesData,
      isTemplatesLoading,
      rootTemplates,
      templatesHash,
      activeTemplate,
      selectTemplate,
      searchValue,
      setSearchValue,
      parentList,
      updateTemplate,
      activeTemplateData,
      updatedEvidenceRules,
      setUpdatedEvidenceRules,
      saveTemplateChanges,
      createTemplate,
      removeTemplate,
      isLoading,
      generateRules,
      saveEvidenceRules,
      jsonConfig
    ]
  )

  return (
    <TemplateContext.Provider value={contextValue}>
      {children}
    </TemplateContext.Provider>
  )
}

export const useTemplateContext = () => {
  return useContext(TemplateContext)
}
