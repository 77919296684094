import React, { useCallback } from 'react'
import debounce from 'lodash.debounce'
import { makeStyles } from '@material-ui/core/styles'
import { TextInput } from '@siftscience/focus-components/input'
import {
  DoubleChevronUp,
  Search as SearchIcon
} from '@siftscience/focus-components/icons'
import { IconButton } from '@siftscience/focus-components/button'
import { useTemplateContext } from '../context/template-context'

const ONCHANGE_DEBOUNCE_TIME = 500

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    width: '100%',
    padding: '16px',
    borderBottom: '1px solid #E0E0E0'
  },
  input: {
    width: '224px'
  }
}))

const Search = (): React.ReactElement => {
  const { templatesHash, setSearchValue } = useTemplateContext()
  const classes = useStyles()

  const onSearchChange = useCallback(
    debounce(event => {
      const value = event.target.value
      setSearchValue(value)
    }, ONCHANGE_DEBOUNCE_TIME),
    [setSearchValue]
  )

  return (
    <div className={classes.wrapper}>
      <TextInput
        icon={<SearchIcon />}
        placeholder={`Search through ${templatesHash.size} templates`}
        onChange={onSearchChange}
        className={classes.input}
      />
      {/* TODO add on click logic after Ivan Dankov answers comment in figma */}
      <IconButton variant="secondary-ghost">
        <DoubleChevronUp />
      </IconButton>
    </div>
  )
}

export default Search
