export const REASON_CODES_COLORS = {
  authorization: '#295DED',
  cancel_subscription: '#F2B200',
  credit_not_processed: '#33AFC5',
  duplicate: '#9728ED',
  fraud: '#C71C0A',
  non_receipt: '#6AD7B7',
  processing_error: '#BA2081',
  unacceptable: '#3B93E8',
  unrecognized: '#91BD34'
}

export const REASON_CODES_LIST = [
  'authorization',
  'cancel_subscription',
  'credit_not_processed',
  'duplicate',
  'fraud',
  'non_receipt',
  'processing_error',
  'unacceptable',
  'unrecognized'
]
