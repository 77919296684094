import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from '@siftscience/focus-components/text'
import { Escalate } from '@siftscience/focus-components/icons'
import { useTemplateContext } from './context/template-context'
import TemplateViewer from './template-editor-components/template-viewer'

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: isInitialView => (isInitialView ? '64px' : '32px'),
    paddingLeft: isInitialView => (isInitialView ? '0' : '32px'),
    paddingRight: isInitialView => (isInitialView ? '0' : '32px'),
    backgroundColor: '#F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: '1',
    maxWidth: 'calc(100% - 320px)' // 320px is width of navigation bar
  },
  initialWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1',
    width: '100%',
    gap: '16px'
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px 0px #24242429',
    width: '48px',
    height: '48px',
    borderRadius: '24px'
  },
  icon: {
    width: '24px',
    transform: 'rotate(90deg)'
  }
}))

const MainView = (): React.ReactElement => {
  const { templatesHash, activeTemplate } = useTemplateContext()
  const isInitialView = !activeTemplate
  const classes = useStyles(isInitialView)

  const template = templatesHash.get(activeTemplate)

  return (
    <div className={classes.wrapper}>
      {isInitialView ? (
        <div className={classes.initialWrapper}>
          <Text size="medium">Select a template to start editing</Text>
          <div className={classes.iconWrapper}>
            <Escalate className={classes.icon} color="#295DED" />
          </div>
        </div>
      ) : (
        <TemplateViewer template={template} />
      )}
    </div>
  )
}

export default MainView
