import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { AutomationDTO } from '../dto/automation'
import {
  saveChangesToAutomation,
  useAutomation,
  useConfig
} from '../hooks/useAutomation'

interface IAutomationContext {
  automationId: string
  automation: AutomationDTO | null
  setAutomation: (automation: AutomationDTO) => void
  activeMode: number
  setActiveMode: (mode: number) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  config: {
    actions: {
      klass: Record<string, unknown>
      desc: string
      args: Record<string, unknown>
      opts: Record<string, unknown>
    }
    events: Record<string, { title: string }>
    fields: Record<string, unknown>
  }
  saveAutomation: (automation: AutomationDTO) => Promise<void>
}

const AutomationContext = createContext<IAutomationContext>({})

export const AutomationProvider = ({ children }) => {
  const { id: automationId } = useParams<{ id: string }>()
  const { config, isConfigLoading } = useConfig()
  const { automation: beAutomation, isAutomationLoading } =
    useAutomation(automationId)
  const [automation, setAutomation] = useState<AutomationDTO | null>(null)
  // 0 for Builder and 1 for Code
  const [activeMode, setActiveMode] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const saveAutomation = async (automation: AutomationDTO) => {
    setIsLoading(true)
    await saveChangesToAutomation(automation)
    setAutomation(automation)
    setIsLoading(false)
  }

  useEffect(() => {
    if (beAutomation) {
      setAutomation(beAutomation)
    }
  }, [beAutomation])

  const contextValue = useMemo(
    () => ({
      automationId,
      automation,
      setAutomation,
      activeMode,
      setActiveMode,
      isLoading: isLoading || isConfigLoading || isAutomationLoading,
      setIsLoading,
      config,
      saveAutomation
    }),
    [
      automationId,
      automation,
      setAutomation,
      activeMode,
      setActiveMode,
      isLoading,
      setIsLoading,
      config,
      isConfigLoading,
      isAutomationLoading,
      saveAutomation
    ]
  )

  return (
    <AutomationContext.Provider value={contextValue}>
      {children}
    </AutomationContext.Provider>
  )
}

export const useAutomationContext = () => {
  return useContext(AutomationContext)
}
