import React from 'react'
import { Text, Heading } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import TemplateToolingHeaderDropdown from './dropdown'
import { Button } from '@siftscience/focus-components/button'
import { Save } from '@siftscience/focus-components/icons'
import { useProcessorContext } from './context/processor-context'
import { useMerchantContext } from './context/merchant-context'
import { useTemplateContext } from './context/template-context'

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '16px 24px',
    width: '100%',
    height: '84px',
    boxShadow: '0px 2px 8px 0px #24242429'
  },
  title: {
    marginRight: '16px'
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  }
}))

const TemplateToolingHeader = (): React.ReactElement => {
  const { displayMerchantList, selectedMerchant, setSelectedMerchant } =
    useMerchantContext()
  const { displayProcessorList, selectedProcessor, setSelectedProcessor } =
    useProcessorContext()
  const { saveTemplateChanges } = useTemplateContext()
  const classes = useStyles()

  const onSaveChanges = () => {
    saveTemplateChanges()
    // TODO move to Context during integration with BE
    console.log('changes are saved')
  }

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerLeft}>
        <Heading className={classes.title} size="4">
          Templates
        </Heading>
        <TemplateToolingHeaderDropdown
          title="Merchant"
          items={displayMerchantList}
          onSelect={setSelectedMerchant}
          selectedItem={selectedMerchant}
          placeholder="Select Merchant"
        />
        <TemplateToolingHeaderDropdown
          title="Payment Processor"
          items={displayProcessorList}
          onSelect={setSelectedProcessor}
          selectedItem={selectedProcessor}
          placeholder="Select Payment Processor"
        />
      </div>
      <Button onClick={onSaveChanges}>
        <Save />
        <Text>Save Changes</Text>
      </Button>
    </div>
  )
}

export default TemplateToolingHeader
