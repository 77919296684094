import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'

export const useJsonConfig = (merchantId: number) => {
  let queryString = ''
  if (merchantId) {
    queryString += `?merchant_id=${merchantId}`
  }

  const {
    data: { data: jsonConfig } = {},
    isLoading: isJsonConfigLoading,
    refetch: refetchJsonConfig
  } = useQuery(
    `json-config-${queryString}`,
    () =>
      axios.get<Record<string, unknown>>(
        `/det/tooling/evidence_rules/json_logic_config${queryString}`
      ),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  return {
    jsonConfig: jsonConfig ? { fields: jsonConfig } : {},
    isJsonConfigLoading,
    refetchJsonConfig
  }
}
