import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { NONE_MERCHANT } from '../context/merchant-context'

export const useAutomationList = (
  merchantId: number,
  size?: number,
  page?: number
) => {
  let url = '/api/automations'
  if (merchantId !== NONE_MERCHANT.id) {
    url += `?merchant_id=${merchantId}`
  }

  if (size) {
    url += url.includes('?') ? `&size=${size}` : `?size=${size}`
  }

  if (page) {
    url += url.includes('?') ? `&page=${page}` : `?page=${page}`
  }

  const {
    data: { data: automationList } = {},
    isLoading: isAutomationListLoading
  } = useQuery(
    `automation-list-${merchantId}-size-${size}-page-${page}`,
    () => axios.get(url),
    {
      refetchOnWindowFocus: false
    }
  )

  return {
    automationList,
    isAutomationListLoading
  }
}

// Not hook, but purpose is similar to the hooks here
export const deleteAutomation = async (id: number) => {
  return axios.delete(`/api/automations/${id}`)
}
