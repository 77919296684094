import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useMerchantContext } from './merchant-context'
import { useDataFields } from '../hooks/useDataField'
import { createNewDataField } from '../utils/data-field-utils'
import { DataField } from '../dto/template'

interface IDataFieldContext {
  dataFields?: DataField[]
  isDataFieldsLoading?: boolean
  dataFieldsHash?: Map<number, DataField>
  addNewDataField: (dataField: DataField) => Promise<void>
}

const DataFieldContext = createContext<IDataFieldContext>({})

export const DataFieldProvider = ({ children }) => {
  const { selectedMerchant } = useMerchantContext()
  const { dataFieldsData, isDataFieldsLoading, refetchDataFields } =
    useDataFields(selectedMerchant?.id)
  const [dataFieldsHash, setDataFieldsHash] = useState<Map<number, DataField>>(
    new Map()
  )

  useEffect(() => {
    if (dataFieldsData) {
      const hash = new Map()
      dataFieldsData.forEach(dataField => {
        hash.set(dataField.id, dataField)
      })
      setDataFieldsHash(hash)
    } else {
      setDataFieldsHash(new Map())
    }
  }, [dataFieldsData])

  const addNewDataField = async (dataField: Partial<DataField>) => {
    await createNewDataField(dataField)
    await refetchDataFields()
  }

  const contextValue = useMemo(
    () => ({
      dataFields: dataFieldsData,
      isDataFieldsLoading,
      dataFieldsHash,
      addNewDataField
    }),
    [dataFieldsData, isDataFieldsLoading, dataFieldsHash, addNewDataField]
  )

  return (
    <DataFieldContext.Provider value={contextValue}>
      {children}
    </DataFieldContext.Provider>
  )
}

export const useDataFieldContext = () => {
  return useContext(DataFieldContext)
}
