import React, { useMemo } from 'react'
import { Text, Title } from '@siftscience/focus-components/text'
import { Button, IconButton } from '@siftscience/focus-components/button'
import { Plus } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dropdown,
  DropdownMenuContainer
} from '@siftscience/focus-components/dropdown'
import ReasonCodeBadge from './reason-code-badge'
import { REASON_CODES_COLORS, REASON_CODES_LIST } from '../consts/reason-codex'
import { titleizeText } from '../../DET-edit-additions/utils'
import { useTemplateContext } from '../context/template-context'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  reasonCodes: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    width: '75%',
    overflowX: 'auto'
  },
  reasonCodeDropdownItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '32px',
    alignItems: 'center',
    gap: '12px',
    width: '202px',
    paddingLeft: '8px',
    paddingRight: '6px',
    fontWeight: 400
  },
  reasonCodeDropdownDot: {
    width: '8px',
    height: '8px',
    borderRadius: '100%'
  }
}))

interface ReasonCodesRowProps {
  readonly?: boolean
}

const ReasonCodesRow = ({ readonly }: ReasonCodesRowProps) => {
  const { activeTemplateData, updatedEvidenceRules, setUpdatedEvidenceRules } =
    useTemplateContext()
  const classes = useStyles()
  const templateReasonCodes =
    activeTemplateData?.evidenceRules?.[0]?.reason_code_categories
  const reasonCodesToOperate = readonly
    ? templateReasonCodes
    : updatedEvidenceRules?.reason_code_categories || templateReasonCodes

  const reasonCodeOptions = useMemo(() => {
    if (!reasonCodesToOperate) {
      return REASON_CODES_LIST
    } else {
      return REASON_CODES_LIST.filter(
        item => !reasonCodesToOperate.includes(item)
      )
    }
  }, [reasonCodesToOperate])

  const updateReasonCodes = (reasonCodes: string[]) => {
    if (updatedEvidenceRules) {
      const newUpdatedEvidenceRules = {
        ...updatedEvidenceRules,
        reason_code_categories: reasonCodes
      }
      setUpdatedEvidenceRules(newUpdatedEvidenceRules)
    } else {
      const templateEvidenceRules = activeTemplateData?.evidenceRules?.[0]
      const newUpdatedEvidenceRules = {
        ...templateEvidenceRules,
        reason_code_categories: reasonCodes
      }
      setUpdatedEvidenceRules(newUpdatedEvidenceRules)
      // TODO for the future: potential 1 more if for the case when there is no evidence rule created prior
    }
  }

  const onDelete = (reasonCode: string) => {
    const updatedReasonCodes = reasonCodesToOperate.filter(
      code => code !== reasonCode
    )
    updateReasonCodes(updatedReasonCodes)
  }

  const onAdd = (reasonCode: string) => {
    const updatedReasonCodes = [...(reasonCodesToOperate || [])]
    if (!reasonCodesToOperate?.includes(reasonCode)) {
      updatedReasonCodes.push(reasonCode)
    }
    updateReasonCodes(updatedReasonCodes)
  }

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Reason Codes</Title>
      <div className={classes.reasonCodes}>
        {reasonCodesToOperate?.map(reasonCodeCategory => (
          <ReasonCodeBadge
            key={`${reasonCodeCategory}-badge`}
            reasonCode={reasonCodeCategory}
            onDelete={() => onDelete(reasonCodeCategory)}
            readonly={readonly}
          />
        ))}
      </div>
      {!readonly && (
        <Dropdown
          placement="bottom"
          displayRenderer={({ getToggleButtonProps }) => (
            <IconButton variant="secondary" lined {...getToggleButtonProps()}>
              <Plus />
            </IconButton>
          )}
          dropdownRenderer={({ isOpen, getMenuProps }) => {
            if (!isOpen) {
              return null
            }

            const { style, ...props } = getMenuProps(undefined, {
              suppressRefError: true
            })

            return (
              <DropdownMenuContainer {...props} style={{ ...style }}>
                {reasonCodeOptions.map(reasonCode => (
                  <Button
                    className={classes.reasonCodeDropdownItem}
                    variant="secondary-ghost"
                    onClick={() => onAdd(reasonCode)}
                  >
                    <div
                      className={classes.reasonCodeDropdownDot}
                      style={{
                        backgroundColor: REASON_CODES_COLORS[reasonCode]
                      }}
                    />
                    <Text size="small">{titleizeText(reasonCode)}</Text>
                  </Button>
                ))}
              </DropdownMenuContainer>
            )
          }}
        />
      )}
    </div>
  )
}

export default ReasonCodesRow
