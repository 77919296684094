import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from '@siftscience/focus-components/text'
import { IconButton } from '@siftscience/focus-components/button'
import {
  ChevronDown,
  ChevronUp,
  Override
} from '@siftscience/focus-components/icons'
import NavigationItem from './navigation-item'
import { TemplateDTO } from '../dto/template'
import { useTemplateContext } from '../context/template-context'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: '16px'
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '40px',
    padding: '10px 16px',
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px'
  },
  title: {
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px'
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}))

interface RootTemplateProps {
  template: TemplateDTO
}

const RootTemplate = ({ template }: RootTemplateProps): React.ReactElement => {
  const { searchValue, selectTemplate } = useTemplateContext()
  const [isOpen, setIsOpen] = useState(true)
  const classes = useStyles()
  const isSearchFit = template?.displayName
    ?.toLowerCase()
    ?.includes(searchValue?.toLowerCase())

  return (
    <div className={classes.wrapper}>
      {isSearchFit && (
        <div
          className={classes.headerWrapper}
          onClick={() => selectTemplate(template.templateId, '')}
        >
          <Title className={classes.title} size="small">
            {template.displayName}
          </Title>
          <div className={classes.actions}>
            {/* TODO add conditional logic when to show Override icon after BE ready */}
            <Override className={classes.icon} />
            <IconButton
              variant="secondary-ghost"
              onClick={event => {
                event.stopPropagation()
                setIsOpen(!isOpen)
              }}
            >
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </div>
        </div>
      )}
      {isOpen &&
        template.childTemplateIds.map(id => (
          <NavigationItem
            key={id}
            templateId={id}
            level={0}
            parent={`${template.templateId}`}
          />
        ))}
    </div>
  )
}

export default RootTemplate
