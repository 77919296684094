import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TemplateHeader from './template-header'
import TemplateEditor from './template-editor'
import { TemplateDTO } from '../dto/template'
import { NONE_MERCHANT, useMerchantContext } from '../context/merchant-context'
import { useCurrentUser } from '../../../contexts/CurrentUserContext'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    maxWidth: '100%'
  },
  viewerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '16px',
    marginTop: '16px',
    height: '100%',
    maxWidth: '100%'
  }
}))

interface TemplateViewerProps {
  template: TemplateDTO
}

const TemplateViewer = ({
  template
}: TemplateViewerProps): React.ReactElement => {
  const { selectedMerchant } = useMerchantContext()
  const { currentUser } = useCurrentUser()
  const classes = useStyles()

  const isGPDEditAllowed = currentUser.hasAccess(['Super Administrator'])
  const isNoneMerchantSelected = selectedMerchant?.id === NONE_MERCHANT?.id
  const isOverrideTemplate = template?.merchantId
  const isOverrideVisible = !isNoneMerchantSelected && isOverrideTemplate

  return (
    <div className={classes.wrapper}>
      <TemplateHeader template={template} />
      <div className={classes.viewerWrapper}>
        <TemplateEditor
          title="Processor Default"
          readonly={!isGPDEditAllowed}
          template={template}
          isFullWidth={!isOverrideVisible}
        />
        {isOverrideVisible && (
          <TemplateEditor title="Override" template={template} />
        )}
      </div>
    </div>
  )
}

export default TemplateViewer
