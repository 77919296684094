import React from 'react'
import { Settings } from '@siftscience/focus-components/icons'
import HeaderMenu from './header-menu'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined'
import StorageOutlineIcon from '@material-ui/icons/StorageOutlined'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

const insertIf = (condition, ...elements) => (condition ? elements : [])

const TOOLTIP_TITLE = 'User menu'

const UserMenu = () => {
  const { currentUser } = useCurrentUser()

  const menuItems = [
    {
      href: '/log_out',
      text: 'Log Out',
      icon: <ExitToAppIcon />
    },
    {
      href: `/users/${currentUser.id}/edit`,
      text: 'Account Settings',
      icon: <AccountCircleOutlinedIcon />
    },
    {
      href: '/manage_merchant_users',
      text: 'Manage team',
      icon: <PeopleOutlineIcon />
    },
    ...insertIf(currentUser.clientId, {
      href: `/clients/${currentUser.clientId}`,
      text: 'Billing',
      icon: <ReceiptOutlinedIcon />
    }),
    {
      href: '/notification_subscriptions',
      text: 'Notifications',
      icon: <NotificationsNoneIcon />
    },
    {
      href: `/users/${currentUser.id}/evidence`,
      text: 'Configure evidence',
      icon: <FindInPageOutlinedIcon />
    },
    { href: '/tags', text: 'Configure tags', icon: <LocalOfferOutlinedIcon /> },
    {
      href: `/users/${currentUser.id}/business_summary`,
      text: 'Business summary',
      icon: <BusinessOutlinedIcon />
    },
    ...insertIf(!currentUser.chargebackEmployee, {
      href: '/connections',
      text: 'Add connections',
      icon: <StorageOutlineIcon />
    }),
    ...insertIf(currentUser.admin, {
      href: '/ui/disputebot',
      text: 'Disputebot Rules',
      icon: <DeviceHubIcon />
    })
  ]

  return (
    <HeaderMenu
      IconComponent={Settings}
      menuItems={menuItems}
      popupId="user"
      tooltipTitle={TOOLTIP_TITLE}
    />
  )
}

export default UserMenu
