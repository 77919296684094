import React, { useState } from 'react'
import { Button } from '@siftscience/focus-components/button'
import { Modal } from '@siftscience/focus-components/modal'
import { Text, Title } from '@siftscience/focus-components/text'
import { TextInput } from '@siftscience/focus-components/input'
import { useAutomationContext } from './context/automation-context'
import { testAutomation } from './hooks/useAutomation'
import { makeStyles } from '@material-ui/core/styles'

const MODAL_ACTIONS_TITLES = {
  test: 'Test',
  cancel: 'Cancel'
}

const useStyles = makeStyles(() => ({
  modalBodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  }
}))

const AutomationTest = () => {
  const { automationId } = useAutomationContext()
  const [modalState, setModalState] = useState({
    visible: false,
    disputeId: '',
    testResult: ''
  })
  const classes = useStyles()

  const openModal = () => {
    setModalState({ visible: true, disputeId: '', testResult: '' })
  }

  const onCloseModal = () => {
    setModalState({ visible: false, disputeId: '', testResult: '' })
  }

  const onDisputeIdChange = event => {
    const value = event?.target?.value
    setModalState(prevState => ({ ...prevState, disputeId: value }))
  }

  const onAction = async (action: { label }) => {
    if (action.label === MODAL_ACTIONS_TITLES.test) {
      try {
        const result = await testAutomation(automationId, modalState.disputeId)
        setModalState(prevState => ({ ...prevState, testResult: result.data }))
      } catch (e) {
        console.error(e)
      }
    } else {
      onCloseModal()
    }
  }

  if (!automationId) {
    return <div />
  }

  return (
    <>
      <Button lined variant="secondary" onClick={openModal}>
        Test
      </Button>
      {modalState?.visible ? (
        <Modal
          actions={[
            {
              label: MODAL_ACTIONS_TITLES.test,
              variant: 'primary'
            },
            {
              label: MODAL_ACTIONS_TITLES.cancel,
              lined: true,
              variant: 'secondary'
            }
          ]}
          closeable
          onAction={onAction}
          onClickOutside={onCloseModal}
          onCloseButtonClick={onCloseModal}
          size="small"
          title={`Test Automation ${automationId}`}
          variant="fixed"
          visible
        >
          <div className={classes.modalBodyWrapper}>
            <Title size="xsmall">Dispute Id</Title>
            <TextInput
              value={modalState.disputeId}
              onChange={onDisputeIdChange}
            />
            <Text size="medium">Test result is: {modalState.testResult}</Text>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default AutomationTest
