import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { TemplateDTO } from '../dto/template'

export const useTemplates = (merchantId, processorId) => {
  let queryString = ''
  if (merchantId) {
    queryString += `merchant_id=${merchantId}&`
  }
  if (processorId) {
    queryString += `processor_id=${processorId}`
  }

  const {
    data: templatesData,
    isLoading: isTemplatesLoading,
    refetch: refetchTemplates
  } = useQuery(
    `templates-list-${queryString}`,
    () => axios.get<TemplateDTO[]>(`/det/tooling/templates?${queryString}`),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!processorId
    }
  )

  return {
    templatesData: templatesData?.data as TemplateDTO[],
    isTemplatesLoading,
    refetchTemplates
  }
}
