import React from 'react'
import { Link } from 'react-router-dom'
import { Text, Heading } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@siftscience/focus-components/button'
import { Plus } from '@siftscience/focus-components/icons'
import TemplateToolingHeaderDropdown from '../template-tooling/dropdown'
import { useMerchantContext } from './context/merchant-context'
import { MerchantDTO } from './dto/merchant'

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '16px 24px',
    width: '100%',
    height: '84px',
    boxShadow: '0px 2px 8px 0px #24242429'
  },
  title: {
    marginRight: '16px'
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  }
}))

const DisputeBotListHeader = (): React.ReactElement => {
  const { displayMerchantList, selectedMerchant, setSelectedMerchant } =
    useMerchantContext()
  const classes = useStyles()

  const selectMerchant = (merchant: MerchantDTO) => {
    setSelectedMerchant(merchant.id)
  }

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerLeft}>
        <Heading className={classes.title} size="4">
          Disputebot list
        </Heading>
        <TemplateToolingHeaderDropdown
          title="Merchant"
          items={displayMerchantList || []}
          onSelect={selectMerchant}
          selectedItem={displayMerchantList?.find(
            merchant => merchant?.id === selectedMerchant
          )}
          placeholder="Select Merchant"
        />
      </div>
      <Link to={`/ui/disputebot/automation`}>
        <Button>
          <Plus />
          <Text>Create New</Text>
        </Button>
      </Link>
    </div>
  )
}

export default DisputeBotListHeader
