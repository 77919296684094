import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from '@siftscience/focus-components/text'
import { useTemplateContext } from '../context/template-context'
import RootTemplate from './root-template'
import Search from './search'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '320px',
    minHeight: 'calc(100vh - 84px - 64px - 36px)', // 84 for template tooling header, 64 for global header and 34 is for footer
    boxShadow: '0px 1px 4px 0px #24242429'
  }
}))

const Navigation = (): React.ReactElement => {
  const { rootTemplates, isTemplatesLoading } = useTemplateContext()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Search />
      {isTemplatesLoading ? (
        <Title>Loading...</Title>
      ) : rootTemplates && rootTemplates.length ? (
        rootTemplates.map(template => (
          <RootTemplate key={template.templateId} template={template} />
        ))
      ) : (
        <Title>No Templates to display</Title>
      )}
    </div>
  )
}

export default Navigation
