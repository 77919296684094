import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { Text } from '@siftscience/focus-components/text'
import { IconButton } from '@siftscience/focus-components/button'
import { TagDelete } from '@siftscience/focus-components/icons'
import Badge from '../../DET-edit-additions/badge'
import { titleizeText } from '../../DET-edit-additions/utils'
import { REASON_CODES_COLORS } from '../consts/reason-codex'

interface StyleProps {
  iconColor: string
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  badgeChildren: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    padding: '0px 0px 0px 0px',
    gap: '4px'
  },
  reasonCodeIcon: {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    backgroundColor: props => props.iconColor,
    wordBreak: 'normal'
  },
  deleteButton: {
    width: 'auto',
    height: 'auto',
    borderRadius: '50%'
  }
}))

interface ReasonCodeBadgeProps {
  readonly?: boolean
  reasonCode: string
  onDelete: (reasonCode: string) => void
}

const ReasonCodeBadge = ({
  readonly,
  reasonCode,
  onDelete
}: ReasonCodeBadgeProps) => {
  const classes = useStyles({ iconColor: REASON_CODES_COLORS[reasonCode] })
  const onDeleteReasonCode = () => onDelete(reasonCode)

  return (
    <Badge
      color="transparent"
      border
      width="fit-content"
      minWidth="fit-content"
    >
      <div className={classes.badgeChildren}>
        <div className={classes.reasonCodeIcon}></div>
        <Text size="small">{titleizeText(reasonCode)}</Text>
        {!readonly && (
          <IconButton
            className={classes.deleteButton}
            variant="secondary-ghost"
            onClick={onDeleteReasonCode}
          >
            <TagDelete />
          </IconButton>
        )}
      </div>
    </Badge>
  )
}

export default ReasonCodeBadge
