import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'
import { DataField } from '../dto/template'

export const useDataFields = (merchantId?: number) => {
  let url = '/det/tooling/data_fields'
  if (merchantId) {
    url += `?merchant_id=${merchantId}`
  }

  const {
    data: dataFieldsData,
    isLoading: isDataFieldsLoading,
    refetch: refetchDataFields
  } = useQuery(
    `data-fields-list-merchant-${merchantId}`,
    () => axios.get<{ dataFields: DataField[] }>(url),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  return {
    dataFieldsData: (dataFieldsData?.data?.dataFields as DataField[]) || [],
    isDataFieldsLoading,
    refetchDataFields
  }
}
