import React from 'react'
import { Link } from 'react-router-dom'
import { Text, Heading } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@siftscience/focus-components/button'
import {
  createButtonRenderer,
  SegmentedControl
} from '@siftscience/focus-components/segmented-control'
import { Table, Developer } from '@siftscience/focus-components/icons'
import { useAutomationContext } from './context/automation-context'

const SEGMENTED_CONTROL_ITEMS = [
  <>
    <Table />
    <span>Builder</span>
  </>,
  <>
    <Developer />
    <span>Code</span>
  </>
]

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '16px 24px',
    width: '100%',
    height: '84px',
    boxShadow: '0px 2px 8px 0px #24242429'
  },
  title: {
    marginRight: '16px'
  }
}))

const DisputeBotAutomationHeader = (): React.ReactElement => {
  const { automationId, activeMode, setActiveMode } = useAutomationContext()
  const classes = useStyles()
  const headingText = automationId
    ? `Edit Automation ${automationId}`
    : 'Create New Automation'

  return (
    <div className={classes.headerWrapper}>
      <Link to={`/ui/disputebot`}>
        <Button variant="secondary">
          <Text>Go to List</Text>
        </Button>
      </Link>
      <Heading className={classes.title} size="4">
        {headingText}
      </Heading>
      <SegmentedControl
        items={SEGMENTED_CONTROL_ITEMS}
        onSelect={(item, index) => setActiveMode(index)}
        selectedIndex={activeMode}
        size="medium"
        itemRenderer={createButtonRenderer()}
      />
    </div>
  )
}

export default DisputeBotAutomationHeader
