import React from 'react'
import { MerchantProvider } from './merchant-context'
import { ProcessorProvider } from './processor-context'
import { TemplateProvider } from './template-context'
import { DataFieldProvider } from './data-field-context'

// Combines all template-tooling-related providers
export const TemplateToolingProvider = ({ children }) => (
  <MerchantProvider>
    <ProcessorProvider>
      <TemplateProvider>
        <DataFieldProvider>{children}</DataFieldProvider>
      </TemplateProvider>
    </ProcessorProvider>
  </MerchantProvider>
)
