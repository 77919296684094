/**
 * The Quick Search form toggles to the different types of searches
 * The last search used is saved to localStorage
 * When the form is submitted, a URL is retured to redirect to the proper place
 * Each search takes you to the detail page if there is one record,
 * or the list page if there are many or no records
 */

import React, { useRef, useState } from 'react'
import {
  TextInputBase,
  renderBorderlessInputContainer
} from '@siftscience/focus-components/input'
import { Search as SearchIcon } from '@siftscience/focus-components/icons/Search'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, IconButton, Menu, MenuItem } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks'
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../../utils/local-storage'
import axios from '../../utils/http/axios-local'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

const searches = [
  { label: 'Dispute ID', name: 'id' },
  { label: 'Transaction ID', name: 'transaction_id' },
  { label: 'Case Number', name: 'case_number' },
  { label: 'Reference Number', name: 'reference_number' },
  { label: 'Invoice ID', name: 'invoice_id' }
]

const customSearchColumns = [{ label: 'Ticket Number', name: 'ticket_number' }]

/**
 * The object keys must match the labels in the each of the items
 * of the searches array. This is due to the use of several different
 * search parameters being submitted to the same route. Some are Ints,
 * other are Strings.
 */
const searchCriteria = {
  'Dispute ID': 10,
  'Transaction ID': 255,
  'Reference Number': 255,
  'Case Number': 255,
  'Invoice ID': 255,
  'Ticket Number': 14
}

const Search = props => {
  const { currentUser } = useCurrentUser()
  const customSearches = customSearchColumns.filter(
    x => x.name in currentUser.customSearchColumns
  )
  const availableSearches = searches.concat(customSearches)
  const classes = useStyles()
  const lastSearchOptions =
    getFromLocalStorage('lastSearchOptions') || searches[0]
  // select available search option (could happen after masquerading a user so checking if option is available)
  const selectedSerchOption = availableSearches.find(
    x => x.name === lastSearchOptions.name
  )
    ? lastSearchOptions
    : searches[0]
  const [label, setLabel] = useState(selectedSerchOption.label)
  const [name, setName] = useState(selectedSerchOption.name)
  const [value, setValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const searchRef = useRef(null)
  const popupState = usePopupState({ variant: 'popover' })
  popupState.setAnchorEl(searchRef.current)

  const setSearch = ({ label, name, action }) => {
    setLabel(label)
    setName(name)
    saveToLocalStorage('lastSearchOptions', { label, name })
    popupState.close()
  }

  const hasValidId = () => {
    if (!value) return false
    return value.length <= searchCriteria[label]
  }

  const onSubmit = name => event => {
    event.preventDefault()
    if (!hasValidId()) {
      enqueueSnackbar(`Invalid Search ${label}`, { variant: 'error' })
    } else {
      let url
      switch (name) {
        case 'id':
          url = `/api/quick_search/disputes?id=${value}`
          break
        case 'case_number':
          url = `/api/quick_search/disputes?case_number=${value}`
          break
        case 'reference_number':
          url = `/api/quick_search/disputes?reference_number=${value}`
          break
        case 'transaction_id':
          url = `/api/quick_search/transactions?transaction_id=${value}`
          break
        case 'invoice_id':
          url = `/api/quick_search/invoices?invoice_id=${value}`
          break
        case 'ticket_number':
          url = `/api/quick_search/disputes?ticket_number=${value}`
          break
      }
      axios.get(url).then(response => {
        window.location.href = response.data.url
      })
    }
  }

  return (
    <div className={classes.root} ref={searchRef}>
      <form autoComplete="off" onSubmit={onSubmit(name)}>
        <FormControl id="quick-search" variant="outlined">
          <TextInputBase
            id="searchInput"
            containerClassName={classes.searchInput}
            onChange={({ target: { value } }) => setValue(value.trim())}
            placeholder={`Search ${label}`}
            renderChildrenBefore={() => <SearchIcon color={'#7A9EF4'} />}
            renderChildrenAfter={() => (
              <IconButton
                classes={{ root: classes.iconButton }}
                {...bindTrigger(popupState)}
              >
                <ExpandMoreIcon classes={{ root: classes.iconExpandMore }} />
              </IconButton>
            )}
            renderContainer={renderBorderlessInputContainer}
            value={value}
          />
        </FormControl>
      </form>

      <Menu
        classes={{ paper: classes.menuPaper }}
        {...bindMenu(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        getContentAnchorEl={null}
      >
        {availableSearches.map(({ label, name, action }) => {
          return (
            <MenuItem
              key={name}
              classes={{ root: classes.menuItem }}
              onClick={() => setSearch({ label, name, action })}
            >
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles(theme => {
  return {
    '@global': {
      // temp resolve for non-react page style conflict
      '#searchInput': {
        borderBottom: '0 !important',
        height: 'inherit',
        margin: 0,
        padding: 10,
        boxSizing: 'border-box'
      }
    },
    root: {
      marginLeft: 26
    },

    inputLabelRoot: {
      color: theme.palette.primary.contrastText + ' !important',
      backgroundColor: theme.palette.primary.main,
      padding: '0 5px 0 5px'
    },
    oulinedInputRoot: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      padding: '0 5px',
      '& $notchedOutline': {
        borderColor: theme.palette.primary.contrastText
      },
      '&:hover:not($focused) $notchedOutline': {
        borderColor: theme.palette.action.hover
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.action.focus,
        borderWidth: 1
      }
    },
    focused: {},
    notchedOutline: {},
    searchIcon: {
      marginTop: 10,
      color: theme.palette.primary.contrastText
    },
    iconButton: {
      padding: 0
    },
    iconExpandMore: {
      color: theme.palette.primary.contrastText
    },
    menuPaper: {
      marginTop: 5,
      width: 246
    },
    menuItem: {
      paddingLeft: 30
    },
    searchInput: {
      width: '240px',
      height: '32px',
      backgroundColor: '#1D47CB',
      borderRadius: '6px',
      '& svg': {
        color: '#BBCFF9'
      },
      '& input::placeholder': {
        color: '#7A9EF4',
        opacity: 1 /* Firefox */
      },
      '&:focus-within': {
        backgroundColor: '#FFFFFF',
        color: '#242424',

        'input::placeholder': {
          color: '#8A8A8A'
        },

        '& svg': {
          color: '#B3B3B3'
        }
      }
    }
  }
})

export default Search
