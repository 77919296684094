import React, { useState, useEffect, useMemo } from 'react'
import {
  BasicConfig,
  Query,
  Builder,
  Utils as QbUtils
} from '@react-awesome-query-builder/ui'
import AutomationTest from './automation-test'
import { makeStyles } from '@material-ui/core/styles'

const InitialConfig = BasicConfig

const CONFIG = {
  ...InitialConfig
}

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
}))

interface JsonLogicBuilderProps {
  rules: string
  onRulesChange: (rules: string) => void
  genAIRules: string
  onGenAIRulesApplied: () => void
  config: {
    actions: {
      klass: Record<string, unknown>
      desc: string
      args: Record<string, unknown>
      opts: Record<string, unknown>
    }
    events: Record<string, { title: string }>
    fields: Record<string, unknown>
  }
}

const JsonLogicBuilder = ({
  rules,
  onRulesChange,
  genAIRules,
  onGenAIRulesApplied,
  config
}: JsonLogicBuilderProps) => {
  const [builderConfig, setBuilderConfig] = useState({ ...CONFIG })
  const [tree, setTree] = useState<any>()
  const [treeWasChanged, setTreeWasChanged] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (config) {
      setBuilderConfig(prevBuilderConfig => ({
        ...prevBuilderConfig,
        ...(config as any)
      }))
    }
  }, [config])

  useEffect(() => {
    if (treeWasChanged) {
      // We need this code only for initial render
      return
    }

    if (builderConfig?.fields) {
      try {
        const jsonRules = JSON.parse(rules)
        const parsedTree = QbUtils.Import.loadFromJsonLogic(
          jsonRules || {},
          builderConfig
        )
        setTree(parsedTree)
      } catch (e) {
        console.error(e)
      }
    }
  }, [rules, builderConfig, treeWasChanged])

  useEffect(() => {
    if (genAIRules && builderConfig?.fields) {
      const jsonRules = JSON.parse(genAIRules)
      const parsedTree = QbUtils.Import.loadFromJsonLogic(
        jsonRules || {},
        builderConfig
      )
      setTree(parsedTree)
      onGenAIRulesApplied()
    }
  }, [genAIRules, onGenAIRulesApplied, builderConfig])

  const onTreeChange = (immutableTree, config) => {
    try {
      const updatedLogic =
        QbUtils.jsonLogicFormat(immutableTree, config).logic || {}
      const updatedStringRules = JSON.stringify(updatedLogic)
      if (rules === updatedStringRules) {
        // function was called on initial change. There is an infinite loop without this check
        return
      }
      onRulesChange(updatedStringRules)
      setTreeWasChanged(true)
    } catch (e) {
      console.error(e)
    }
  }

  const renderBuilder = useMemo(
    () => props =>
      (
        <div className="query-builder-container" style={{ padding: '10px' }}>
          <div className="query-builder qb-lite">
            <Builder {...props} />
          </div>
        </div>
      ),
    []
  )

  if (!builderConfig.fields) {
    // waiting for the BE config part
    return null
  }

  return (
    <>
      <div className={classes.buttonWrapper}>
        <AutomationTest />
      </div>
      <Query
        {...builderConfig}
        value={tree}
        onChange={onTreeChange}
        renderBuilder={renderBuilder}
      />
    </>
  )
}

export default JsonLogicBuilder
