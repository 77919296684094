import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Text, Title } from '@siftscience/focus-components/text'
import { Override, Building } from '@siftscience/focus-components/icons'
import { useTemplateContext } from '../context/template-context'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: props => props.level * 16
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '40px',
    padding: '10px 16px',
    borderLeft: props => (props.level ? '1px solid #E0E0E0' : null),
    color: props => (props.isTemplateActive ? '#295DED' : '#242424'),
    backgroundColor: props =>
      props.isTemplateActive ? '#EAF1FE' : 'transparent',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#EAF1FE'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px'
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}))

interface NavigationItemProps {
  templateId: number
  level: number
  parent: string // list of parent ids divided with "-"
}

const NavigationItem = ({
  templateId,
  level,
  parent
}: NavigationItemProps): React.ReactElement => {
  const { templatesHash, activeTemplate, searchValue, selectTemplate } =
    useTemplateContext()
  const isTemplateActive = activeTemplate === templateId
  const classes = useStyles({
    level,
    isTemplateActive
  })

  const template = templatesHash.get(templateId)
  const NameComponent = isTemplateActive ? Title : Text
  const isSearchFit = template?.displayName
    ?.toLowerCase()
    ?.includes(searchValue?.toLowerCase())

  return (
    <div className={classes.wrapper}>
      {isSearchFit && (
        <div
          className={classes.headerWrapper}
          onClick={() => selectTemplate(templateId, parent)}
        >
          <NameComponent size="small">{template?.displayName}</NameComponent>
          <div className={classes.actions}>
            {/* TODO add conditional logic when to show Override icon after BE ready */}
            {template.merchantSpecific && (
              <Building
                className={classes.icon}
                color={isTemplateActive ? '#295DED' : '#242424'}
              />
            )}
            <Override
              className={classes.icon}
              color={isTemplateActive ? '#295DED' : '#242424'}
            />
          </div>
        </div>
      )}
      {template?.childTemplateIds.map(id => (
        <NavigationItem
          key={id}
          templateId={id}
          level={level + 1}
          parent={parent + `-${template?.templateId}`}
        />
      ))}
    </div>
  )
}

export default NavigationItem
