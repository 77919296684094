import React, {
  ExoticComponent,
  Fragment,
  useMemo,
  useRef,
  useState
} from 'react'
import { Text, Title } from '@siftscience/focus-components/text'
import { Button, IconButton } from '@siftscience/focus-components/button'
import {
  AddNew,
  AtEmail,
  Calendar,
  CalendarWithDate,
  Checkmark,
  Close,
  FileText,
  NoIcon,
  ReturnLog,
  TextAa,
  TreeStructure,
  List
} from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Popover } from '@siftscience/focus-components/popover'
import { TextInput } from '@siftscience/focus-components/input'
import Action from './action'
import Select, { DropdownItem } from '../select'
import { useTemplateContext } from '../context/template-context'
import { useDataFieldContext } from '../context/data-field-context'
import { DataField, DataFieldType, ProcessorFieldType } from '../dto/template'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row'
  },
  addActionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  addNewTemplateBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    gap: '8px',

    '& svg': {
      width: '16px',
      height: '16px'
    }
  },
  addNewSubtemplateInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'space-between',
    gap: '8px'
  }
}))

interface AddRowProps {
  onAddSubtemplate: (subtemplateId: number) => void
  onAddDataField: (dataFieldId: number) => void
}

const AddRow = ({ onAddSubtemplate, onAddDataField }: AddRowProps) => {
  const [isAddingNewSubtemplate, setIsAddingNewSubtemplate] = useState(false)
  const [isAddingNewDataField, setIsAddingNewDataField] = useState(false)
  const [newTemplateName, setNewTemplateName] = useState('')
  const { templateList, templatesHash, activeTemplateData, createTemplate } =
    useTemplateContext()
  const { dataFields, dataFieldsHash, addNewDataField } = useDataFieldContext()
  const popoverOnClickRef = useRef(null)
  const classes = useStyles()

  const onAddNewSubtemplateClicked = () => {
    setIsAddingNewSubtemplate(true)
  }

  const onAddNewDataFieldClicked = () => {
    setIsAddingNewDataField(true)
  }

  const onSaveNewTemplate = () => {
    setIsAddingNewSubtemplate(false)
    createTemplate({ displayName: newTemplateName })
    // TODO finish it, url doesn't exist yet
  }

  const onCancelCreatingTemplate = () => {
    setIsAddingNewSubtemplate(false)
    setNewTemplateName('')
  }

  const onCancelCreatingDataField = () => {
    setIsAddingNewDataField(false)
  }

  const onNewTemplateNameChange = event => {
    const value = event.target.value
    setNewTemplateName(value)
  }

  const subtemplateOptions = useMemo(() => {
    const filteredTemplates =
      templateList
        ?.filter(
          template =>
            template.templateType === activeTemplateData?.templateType &&
            template.templateId !== activeTemplateData.templateId
        )
        .map(template => ({
          id: template.templateId,
          name: template.displayName
        })) || []
    return filteredTemplates
  }, [templateList, activeTemplateData])

  const dataFieldOptions = useMemo(() => {
    return (
      dataFields.map(dataField => ({
        id: dataField.id,
        name: dataField.name
      })) || []
    )
  }, [templateList, activeTemplateData])

  const renderSubtemplateItem = (item: DropdownItem) => {
    const template = templatesHash.get(item.id as number)
    let Icon: ExoticComponent<{ children?: React.ReactNode; color?: string }> =
      Fragment
    if (template?.processorField?.data_type === ProcessorFieldType.STRING) {
      Icon = TextAa
    } else if (template?.processorField?.data_type === ProcessorFieldType.PDF) {
      Icon = FileText
    } else if (
      template?.processorField?.data_type === ProcessorFieldType.IMAGE
    ) {
      Icon = NoIcon
    }

    return (
      <>
        <Icon color="#B3B3B3" />
        <Text size="small">{item.name}</Text>
      </>
    )
  }

  const renderDataFieldItem = (item: DropdownItem) => {
    const dataField = dataFieldsHash.get(item.id as number)
    let Icon: ExoticComponent<{ children?: React.ReactNode; color?: string }> =
      Fragment
    if (dataField.data_type === DataFieldType.TEXT) {
      Icon = TextAa
    } else if (dataField?.data_type === DataFieldType.IMAGE) {
      Icon = NoIcon
    } else if (dataField?.data_type === DataFieldType.DATE) {
      Icon = Calendar
    } else if (dataField?.data_type === DataFieldType.DATETIME) {
      Icon = CalendarWithDate
    } else if (dataField?.data_type === DataFieldType.BOOLEAN) {
      Icon = TreeStructure
    } else if (dataField?.data_type === DataFieldType.ARRAY) {
      Icon = List
    }

    return (
      <>
        <Icon color="#B3B3B3" />
        <Text size="small">{item.name}</Text>
      </>
    )
  }

  const subtemplateDropdownFooter = isAddingNewSubtemplate ? (
    <div className={classes.addNewSubtemplateInputWrapper}>
      <TextInput value={newTemplateName} onChange={onNewTemplateNameChange} />
      <IconButton onClick={onSaveNewTemplate} variant="secondary" lined>
        <Checkmark color="#0AA636" />
      </IconButton>
      <IconButton onClick={onCancelCreatingTemplate} variant="secondary" lined>
        <Close color="#EB3B2E" />
      </IconButton>
    </div>
  ) : (
    <Button variant="primary-ghost" onClick={onAddNewSubtemplateClicked}>
      <div className={classes.addNewTemplateBtn}>
        <AddNew />
        <Title size="xsmall">Add New Template</Title>
      </div>
    </Button>
  )

  const dataFieldDropdownFooter = (
    <Button variant="primary-ghost" onClick={onAddNewDataFieldClicked}>
      <div className={classes.addNewTemplateBtn}>
        <AddNew />
        <Title size="xsmall">Add New Field</Title>
      </div>
    </Button>
  )

  const onSaveDataField = (dataField: DataField) => {
    addNewDataField(dataField)
  }

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Add</Title>
      <div className={classes.addActionsBlock}>
        <Popover
          placement="top"
          content={() => (
            <Action
              defaultDataType={DataFieldType.IMAGE}
              onCancel={event => {
                if (popoverOnClickRef.current) {
                  popoverOnClickRef.current(event)
                }
              }}
              onSave={onSaveDataField}
            />
          )}
        >
          {props => {
            popoverOnClickRef.current = props.onClick
            return (
              <Button
                variant="secondary"
                className={classes.actionButton}
                lined
                {...props}
              >
                <NoIcon />
                <Title size="xsmall">Image</Title>
              </Button>
            )
          }}
        </Popover>
        <Select
          items={subtemplateOptions}
          onSelect={item => onAddSubtemplate(item.id as number)}
          renderItem={renderSubtemplateItem}
          onOpenChange={onCancelCreatingTemplate}
          renderTrigger={toggleButtonProps => (
            <Button
              variant="secondary"
              className={classes.actionButton}
              lined
              {...toggleButtonProps}
            >
              <ReturnLog />
              <Title size="xsmall">Subtemplate</Title>
            </Button>
          )}
          footerContent={subtemplateDropdownFooter}
        />
        {isAddingNewDataField ? (
          <Popover
            placement="top"
            defaultOpen
            content={() => (
              <Action
                defaultDataType={DataFieldType.TEXT}
                onCancel={event => {
                  if (popoverOnClickRef.current) {
                    popoverOnClickRef.current(event)
                  }
                  setIsAddingNewDataField(false)
                }}
                onSave={dataField => {
                  onSaveDataField(dataField)
                  setIsAddingNewDataField(false)
                }}
              />
            )}
          >
            {props => {
              popoverOnClickRef.current = props.onClick
              return (
                <Button
                  variant="secondary"
                  className={classes.actionButton}
                  lined
                  {...props}
                  onClick={event => {
                    props.onClick(event)
                    setIsAddingNewDataField(false)
                  }}
                >
                  <AtEmail />
                  <Title size="xsmall">Data Field</Title>
                </Button>
              )
            }}
          </Popover>
        ) : (
          <Select
            items={dataFieldOptions}
            onSelect={item => onAddDataField(item.id as number)}
            renderItem={renderDataFieldItem}
            onOpenChange={onCancelCreatingDataField}
            renderTrigger={toggleButtonProps => (
              <Button
                variant="secondary"
                className={classes.actionButton}
                lined
                {...toggleButtonProps}
              >
                <AtEmail />
                <Title size="xsmall">Data Field</Title>
              </Button>
            )}
            footerContent={dataFieldDropdownFooter}
          />
        )}
      </div>
    </div>
  )
}

export default AddRow
