import React from 'react'
import { Text, Title } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import { useTemplateContext } from '../context/template-context'

const useStyles = makeStyles(() => ({
  actionBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: '100%',
    padding: '8px 16px',
    borderTop: '1px solid #E0E0E0'
  },
  merchantsList: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    width: '75%',
    overflowX: 'auto'
  }
}))

const MerchantsAffectedRow = () => {
  const { activeTemplateData } = useTemplateContext()
  const classes = useStyles()

  return (
    <div className={classes.actionBlock}>
      <Title size="xsmall">Merchants affected</Title>
      <div className={classes.merchantsList}>
        <Text size="small">
          {activeTemplateData?.overridenByMerchants?.join(', ')}
        </Text>
      </div>
    </div>
  )
}

export default MerchantsAffectedRow
