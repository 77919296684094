import React from 'react'
import { Helmet } from 'react-helmet'
import DisputeBotAutomationHeader from './header'
import { DisputeBotAutomationProvider } from './context'
import AutomationView from './automation-view'
import { MerchantProvider } from '../template-tooling/context/merchant-context'
import capitalize from '../../utils/capitalize'
import Loader from './loader'
import { useAutomationContext } from './context/automation-context'

const DisputeBotAutomation = (): React.ReactElement => {
  const { isLoading } = useAutomationContext()
  return (
    <>
      <Helmet>
        <title>Sift | {capitalize('Disputebot Automation')}</title>
      </Helmet>
      <div>
        <DisputeBotAutomationHeader />
        <AutomationView />
      </div>
      {isLoading && <Loader />}
    </>
  )
}

const DisputeBotAutomationWithProviders = () => (
  <MerchantProvider>
    <DisputeBotAutomationProvider>
      <DisputeBotAutomation />
    </DisputeBotAutomationProvider>
  </MerchantProvider>
)

export default DisputeBotAutomationWithProviders
